var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{attrs:{"action":"/"},on:{"submit":function($event){$event.preventDefault();return _vm.$emit('submit')}}},[(_vm.canEditClient)?_c('p-form-select',{attrs:{"options":_vm.clientOptions,"label":"Klant","required":""},model:{value:(_vm.value.client),callback:function ($$v) {_vm.$set(_vm.value, "client", $$v)},expression:"value.client"}}):_vm._e(),_c('p-form-textarea',{attrs:{"label":"Referentie/notitie"},model:{value:(_vm.value.notes),callback:function ($$v) {_vm.$set(_vm.value, "notes", $$v)},expression:"value.notes"}}),_c('p-form-select',{attrs:{"label":"Status","options":[
            {
                label: 'Selecteer',
                value: '',
            },
            {
                label: this.$t('status.ready.text'),
                value: 'READY',
            } ],"required":""},model:{value:(_vm.value.status),callback:function ($$v) {_vm.$set(_vm.value, "status", $$v)},expression:"value.status"}}),_c('datepicker',{attrs:{"label":"Datum","required":""},model:{value:(_vm.value.date),callback:function ($$v) {_vm.$set(_vm.value, "date", $$v)},expression:"value.date"}}),_c('button',{staticClass:"btn btn-primary"},[_vm._v(" "+_vm._s(_vm.submitText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }