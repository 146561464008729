<template>
    <form
        @submit.prevent="$emit('submit')"
        action="/"
    >
        <p-form-input
            v-model="value.name"
            label="Bedrijfsnaam"
            required
        />

        <p-form-input
            v-model="value.contactName"
            label="Contactpersoon"
            required
        />

        <p-form-input
            v-model="value.contactPhone"
            label="Telefoonnummer"
            required
        />

        <p-form-input
            v-model="value.street"
            label="Straatnaam"
            required
        />

        <div class="row gutter-sm">
            <div class="col-4">
                <p-form-input
                    v-model="value.streetNumber"
                    label="Huis nr."
                    required
                />
            </div>
            <div class="col-8">
                <p-form-input
                    v-model="value.postalCode"
                    label="Postcode"
                    required
                />
            </div>
        </div>

        <p-form-input
            v-model="value.city"
            label="Plaats"
            required
        />

        <p-form-textarea
            v-model="value.notes"
            label="Notities"
            :rows="4"
            required
        />

        <button class="btn btn-primary">
            {{ submitText }}
        </button>
    </form>
</template>

<script>
    export default {
        name: 'ClientsFields',
        props: {
            submitText: {
                type: String,
                default: 'Opslaan',
            },
            value: {
                required: true,
                type: Object,
            },
        },
    };
</script>
