import { ApolloClient } from 'apollo-client';
import { ApolloLink, Observable } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import jwtDecode from 'jwt-decode';
import Vue from 'vue';
import VueApollo from 'vue-apollo';

import store from '@/store';

Vue.use(VueApollo);

const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                emballage(_, { args, toReference }) {
                    return toReference({
                        __typename: 'Emballage',
                        id: args.id,
                    });
                },
            },
        },
    },
});

const requestLink = new ApolloLink((operation, forward) => (
    new Observable(observer => {
        let handle;

        Promise.resolve(operation)
            .then(operation => {
                const accessToken = store.state.auth.accessToken;

                if (accessToken) {
                    operation.setContext({
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                }
            })
            .then(() => {
                handle = forward(operation).subscribe({
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer)
                });
            })
            .catch(observer.error.bind(observer));

        return () => {
            if (handle) handle.unsubscribe();
        };
    })
));

const defaultClient = new ApolloClient({
    link: ApolloLink.from([
        new TokenRefreshLink({
            accessTokenField: 'accessToken',
            isTokenValidOrUndefined: () => {
                const token = store.state.auth.accessToken;

                if (!token) {
                    return true;
                }

                try {
                    const { exp } = jwtDecode(token);

                    return exp * 1000 >= Date.now();
                } catch {
                    return false;
                }
            },
            fetchAccessToken: () => {
                return fetch(process.env.VUE_APP_API_REFRESH_TOKEN_URL, {
                    method: 'POST',
                    credentials: 'include',
                });
            },
            handleFetch: (accessToken, user) => {
                store.commit('auth/setAccessToken', accessToken);
                store.commit('auth/setUser', user);
            },
            handleError: err => {
                console.warn('Your refresh token is invalid. Try to relogin');
                console.error(err);
            },
        }),
        onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors.length) {
                graphQLErrors.forEach((error) => {
                    Vue.notify({
                        title: error.extensions.code,
                        text: error.message,
                        type: 'danger',
                        duration: -1,
                    });
                });
            }

            if (networkError) console.log(`[Network error]: ${networkError}`);
        }),
        requestLink,
        new HttpLink({
            uri: process.env.VUE_APP_API_URL,
            credentials: 'include',
        }),
    ]),
    cache,
});

const apolloProvider = new VueApollo({
    defaultClient,
});

export default apolloProvider;
