export default {
    nl: {
        status: {
            pending: {
                text: 'In behandeling',
                color: '#ffbc00',
            },
            ready: {
                text: 'Gereed',
                color: '#f97121',
            },
            delivered: {
                text: 'Afgeleverd',
                color: '#73479a',
            },
            billed: {
                text: 'Gefactureerd',
                color: '#4090d5',
            },
            paid: {
                text: 'Betaald',
                color: '#06b987',
            },
            settled: {
                text: 'Verrekend',
                color: '#06b987',
            },
        },
        user: {
            role: {
                ADMIN: 'Beheerder',
                USER: 'Gebruiker',
            },
        },
        orderType: {
            DEBIT: 'Debit',
            CREDIT: 'Credit',
        },
    },
};
