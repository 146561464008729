<template>
    <div v-if="!$apollo.queries.invoice.loading">
        <div class="d-flex mb-3">
            <back :to="{ name: 'invoices' }" />

            <div class="ml-auto d-flex align-items-center">
                <generate-invoice
                    :client="client"
                    :invoice="invoice"
                    :orders="orders"
                    :subtotal="subtotal"
                    :total="total"
                    :vat="vat"
                />
            </div>
        </div>

        <div class="card shadow-lg mb-3">
            <div class="card-body">
                <div class="table-responsive-lg">
                    <table class="table mb-0">
                        <thead>
                            <tr>
                                <th>Order</th>
                                <th>Type</th>
                                <th>Datum</th>
                                <th colspan="2"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="order in orders" :key="order.number">
                                <td class="py-3">
                                    <router-link
                                        :to="{
                                            name:
                                                order.type === 'CREDIT'
                                                    ? 'credit-orders.edit'
                                                    : 'orders.edit',
                                            params: {
                                                id: order._id,
                                                invoiceId: invoice._id,
                                            },
                                        }"
                                        class="text-primary font-weight-bold"
                                    >
                                        #{{ order.number }}
                                    </router-link>
                                </td>
                                <td>
                                    {{ $t(`orderType.${order.type}`) }}
                                </td>
                                <td class="py-3">
                                    {{ order.date | date }}
                                </td>
                                <td colspan="2" class="text-right pr-3 py-3">
                                    {{ order.subtotal | currency }}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="3"></td>
                                <td class="text-right py-3 font-weight-bold">
                                    Subtotaal
                                </td>
                                <td class="text-right font-weight-bold py-3">
                                    {{ subtotal | currency }}
                                </td>
                            </tr>
                            <tr
                                v-for="(vat, percentage) in vat"
                                :key="percentage"
                            >
                                <td colspan="3"></td>
                                <td class="text-right py-1">
                                    {{ percentage }}% over
                                    {{ vat.subtotal | currency }}
                                </td>
                                <td class="text-right py-1">
                                    {{ vat.vat | currency }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3"></td>
                                <td class="text-right py-3 font-weight-bold">
                                    Totaal
                                </td>
                                <td class="text-right font-weight-bold py-3">
                                    {{ total | currency }}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>

        <div class="card shadow-lg">
            <div class="card-header">
                <h3>Factuur #{{ invoice.number }}</h3>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-6 d-flex">
                        <div class="alert border flex-grow-1">
                            <h3 class="mb-2">Algemene informatie</h3>

                            <p>
                                {{ invoice.clientName }}<br />
                                {{ invoice.clientStreet }}
                                {{ invoice.clientStreetNumber }}<br />
                                {{ invoice.clientPostalCode }}
                                {{ invoice.clientCity }}
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6 d-flex">
                        <div class="alert border flex-grow-1">
                            <h3 class="mb-2">Contactpersoon</h3>

                            <p>
                                {{ invoice.clientContactName }}<br />
                                {{ invoice.clientContactPhone || '-' }}
                            </p>
                        </div>
                    </div>
                </div>

                <div
                    v-if="invoice.clientNotes"
                    class="alert alert-warning d-flex align-items-center"
                >
                    <p-icon name="warning" class="mr-3" />
                    <div>
                        <h3 class="mb-1">Notitie:</h3>
                        {{ invoice.clientNotes }}
                    </div>
                </div>

                <form @submit.prevent="update" action="/">
                    <div class="row">
                        <div class="col-12">
                            <datepicker
                                v-model="formData.date"
                                label="Datum"
                                required
                            />
                        </div>
                        <div class="col-12">
                            <p-form-select
                                v-model="formData.status"
                                label="Status"
                                :options="[
                                    {
                                        label: 'Selecteer',
                                        value: '',
                                    },
                                    {
                                        label: this.$t('status.pending.text'),
                                        value: 'PENDING',
                                    },
                                    {
                                        label: this.$t('status.paid.text'),
                                        value: 'PAID',
                                    },
                                ]"
                                required
                            />
                        </div>
                    </div>

                    <p-form-textarea
                        v-model="formData.notes"
                        label="Notities"
                        :rows="4"
                    />

                    <button class="btn btn-primary">Opslaan</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {
    QUERY_INVOICE,
    MUTATION_UPDATE_INVOICE,
    QUERY_INVOICES,
} from '@/graphql/invoice';
import GenerateInvoice from '@/pages/invoices/generate-invoice';

export default {
    name: 'InvoicesEdit',
    components: {
        GenerateInvoice,
    },
    apollo: {
        invoice: {
            query: QUERY_INVOICE,
            variables() {
                return {
                    id: this.$route.params.id,
                };
            },
            result({ data: { invoice } }) {
                this.formData = {
                    status: invoice.status,
                    date: invoice.date,
                    notes: invoice.notes,
                };
            },
        },
    },
    data() {
        return {
            invoice: null,
            formData: null,
        };
    },
    computed: {
        client() {
            return {
                city: this.invoice.clientCity,
                contactName: this.invoice.clientContactName,
                contactPhone: this.invoice.clientContactPhone,
                name: this.invoice.clientName,
                notes: this.invoice.clientNotes,
                postalCode: this.invoice.clientPostalCode,
                street: this.invoice.clientStreet,
                streetNumber: this.invoice.clientStreetNumber,
            };
        },

        orders() {
            if (!this.invoice) return [];

            return this.invoice.orders.map((order) => {
                order.vat = order.lines.reduce((acc, line) => {
                    const vat = line.vat;

                    if (!acc[vat]) {
                        acc[vat] = {
                            vat: 0,
                            subtotal: 0,
                            total: 0,
                        };
                    }

                    acc[vat].vat += ((line.quantity * line.price) / 100) * vat;
                    acc[vat].subtotal += line.quantity * line.price;
                    acc[vat].total = acc[vat].vat + acc[vat].subtotal;

                    return acc;
                }, {});

                order.subtotal = Object.keys(order.vat).reduce(
                    (acc, vat) =>
                        acc +
                        order.vat[vat].subtotal *
                            (order.type === 'CREDIT' ? -1 : 1),
                    0
                );

                order.total = Object.keys(order.vat).reduce(
                    (acc, vat) =>
                        acc +
                        order.vat[vat].total *
                            (order.type === 'CREDIT' ? -1 : 1),
                    0
                );

                return order;
            });
        },

        vat() {
            if (!this.orders.length) return [];

            return this.orders.reduce((acc, order) => {
                Object.keys(order.vat).forEach((vat) => {
                    if (!acc[vat]) {
                        acc[vat] = {
                            vat: 0,
                            subtotal: 0,
                            total: 0,
                        };
                    }

                    acc[vat].vat += order.vat[vat].vat;
                    acc[vat].subtotal +=
                        order.vat[vat].subtotal *
                        (order.type === 'CREDIT' ? -1 : 1);
                    acc[vat].total =
                        acc[vat].vat +
                        acc[vat].subtotal * (order.type === 'CREDIT' ? -1 : 1);
                });

                return acc;
            }, {});
        },

        subtotal() {
            if (!this.orders.length) return 0;

            return this.orders.reduce((acc, order) => acc + order.subtotal, 0);
        },

        total() {
            if (!this.orders.length) return 0;

            return this.orders.reduce((acc, order) => acc + order.total, 0);
        },
    },
    methods: {
        async update() {
            await this.$apollo.mutate({
                mutation: MUTATION_UPDATE_INVOICE,
                variables: {
                    id: this.invoice._id,
                    ...this.formData,
                },
                refetchQueries: [{ query: QUERY_INVOICES }],
            });

            this.$notify({
                title: 'Factuur is succesvol aangepast!',
                type: 'success',
                duration: 1000,
            });
        },
    },
};
</script>
