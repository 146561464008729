var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleUpdate.apply(null, arguments)}}},[_c('div',{staticClass:"row gutter-sm"},[_c('div',{staticClass:"col-lg-auto mb-3 mb-lg-0"},[_c('button',{staticClass:"btn btn-symbol btn-secondary btn-lg",attrs:{"type":"button","aria-label":"Verwijder"},on:{"click":_vm.handleDelete}},[_c('p-icon',{attrs:{"name":"delete"}})],1)]),_c('div',{staticClass:"col-lg mb-3 mb-lg-0"},[_c('p-form-input',{attrs:{"label":"Beschrijving","parent-class":"mb-0","required":""},model:{value:(_vm.inputVariant.description),callback:function ($$v) {_vm.$set(_vm.inputVariant, "description", $$v)},expression:"inputVariant.description"}})],1),_c('div',{staticClass:"col-lg mb-3 mb-lg-0"},[_c('p-form-select',{attrs:{"label":"Eenheid","options":[
                    {
                        label: 'Colli',
                        value: 'PACKAGE',
                    },
                    {
                        label: 'Kilogram',
                        value: 'KILOGRAM',
                    },
                    {
                        label: 'Stuks',
                        value: 'PIECE',
                    } ],"parent-class":"mb-0","required":""},model:{value:(_vm.inputVariant.quantityType),callback:function ($$v) {_vm.$set(_vm.inputVariant, "quantityType", $$v)},expression:"inputVariant.quantityType"}})],1),_c('div',{staticClass:"col-lg mb-3 mb-lg-0"},[_c('p-form-input-money',{attrs:{"label":"Prijs","parent-class":"mb-0","required":""},model:{value:(_vm.inputVariant.price),callback:function ($$v) {_vm.$set(_vm.inputVariant, "price", $$v)},expression:"inputVariant.price"}})],1),_c('div',{staticClass:"col-lg-auto"},[_c('button',{staticClass:"btn btn-primary btn-symbol btn-lg",attrs:{"disabled":!_vm.canUpdate,"aria-label":"Opslaan"}},[_c('p-icon',{attrs:{"name":"save"}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }