import Vue from 'vue';
import {
    setupCalendar,
    DatePicker,
} from 'v-calendar';

setupCalendar({
    firstDayOfWeek: 2,
    navVisibility: 'focus',
    popoverVisibility: 'focus',
    locale: 'nl',
});

Vue.component('v-date-picker', DatePicker);
