<template>
    <div>
        <div class="row">
            <div class="col-lg-8 mb-3 mb-lg-0">
                <div class="card shadow-lg">
                    <div class="card-header">
                        <h3>Emballage</h3>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive-lg">
                            <table class="table mb-0">
                                <thead>
                                    <tr>
                                        <th>
                                            Naam
                                        </th>
                                        <th>
                                            Prijs
                                        </th>
                                        <th>
                                            BTW
                                        </th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="item in emballages"
                                        :key="item._id"
                                    >
                                        <td>{{ item.title }}</td>
                                        <td>{{ item.price | currency }}</td>
                                        <td>{{ item.vat | number }}%</td>
                                        <td class="narrow">
                                            <div class="d-flex align-items-center">
                                                <router-link
                                                    :to="{ name: 'emballage.edit', params: { id: item._id } }"
                                                    class="btn btn-symbol btn-primary mr-2"
                                                    aria-label="Wijzig"
                                                >
                                                    <p-icon name="edit" />
                                                </router-link>
                                                <button
                                                    @click="handleDelete(item._id)"
                                                    class="btn btn-symbol btn-secondary"
                                                    aria-label="Verwijder"
                                                >
                                                    <p-icon name="delete" />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="card shadow-lg">
                    <div class="card-header">
                        <h3>Toevoegen</h3>
                    </div>
                    <div class="card-body">
                        <form
                            @submit.prevent="create"
                            action="/"
                        >
                            <p-form-input
                                v-model="formData.title"
                                label="Titel"
                                required
                            />

                            <p-form-input-money
                                v-model="formData.price"
                                label="Prijs"
                                required
                            />

                            <p-form-input-number
                                v-model="formData.vat"
                                label="BTW"
                                :precision="0"
                                required
                            />

                            <button class="btn btn-primary">
                                Toevoegen
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        QUERY_EMBALLAGES,
        MUTATION_DELETE_EMBALLAGE,
        MUTATION_CREATE_EMBALLAGE,
    } from '@/graphql/emballage';

    export default {
        name: 'EmballageIndex',
        apollo: {
            emballages: QUERY_EMBALLAGES,
        },
        data() {
            return {
                formData: {
                    price: '',
                    title: '',
                    vat: '',
                },
            };
        },
        methods: {
            async create() {
                const cachedFormData = JSON.parse(JSON.stringify(this.formData));

                this.formData.price = '';
                this.formData.title = '';
                this.formData.vat = '';

                try {
                    await this.$apollo.mutate({
                        mutation: MUTATION_CREATE_EMBALLAGE,
                        variables: {
                            price: +cachedFormData.price,
                            title: cachedFormData.title,
                            vat: +cachedFormData.vat,
                        },
                        update: (store, { data: { createEmballage } }) => {
                            const queryData = store.readQuery({
                                query: QUERY_EMBALLAGES,
                            });

                            queryData.emballages.push(createEmballage);

                            store.writeQuery({
                                query: QUERY_EMBALLAGES,
                                data: queryData,
                            });
                        },
                    });

                    this.$notify({
                        title: 'Emballage is succesvol toegevoegd!',
                        type: 'success',
                        duration: 1000,
                    });
                } catch (err) {
                    this.formData = cachedFormData;
                }
            },
            async handleDelete(id) {
                await this.confirmModal();

                await this.$apollo.mutate({
                    mutation: MUTATION_DELETE_EMBALLAGE,
                    variables: { id },
                    update: (store) => {
                        const queryData = store.readQuery({
                            query: QUERY_EMBALLAGES,
                        });

                        const index = queryData.emballages.findIndex(emballage => (
                            id === emballage._id
                        ));

                        queryData.emballages.splice(index, 1);

                        store.writeQuery({
                            query: QUERY_EMBALLAGES,
                            data: queryData,
                        });
                    },
                });

                this.$notify({
                    title: 'Emballage is succesvol verwijderd!',
                    type: 'success',
                    duration: 1000,
                });
            },
        },
    };
</script>
