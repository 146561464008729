<template>
    <div>
        <div class="mb-3">
            <form @submit.prevent="create">
                <div class="card shadow-lg">
                    <div class="card-header">
                        <h3>Factuur aanmaken</h3>
                    </div>
                    <div class="card-body border-bottom">
                        <div class="row mb-n3">
                            <div class="col-12 col-md-6">
                                <p-form-select
                                    v-model="formData.client"
                                    :options="clientOptions"
                                    label="Klant"
                                />
                            </div>
                            <div class="col-12 col-md-6">
                                <datepicker
                                    v-model="formData.date"
                                    label="Datum"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive-lg">
                            <table class="table mb-0">
                                <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th>Ordernummer</th>
                                        <th>Status</th>
                                        <th>Klant</th>
                                        <th>Type</th>
                                        <th>Datum</th>
                                    </tr>
                                </thead>
                                <tbody v-if="filteredOrders.length">
                                    <tr
                                        v-for="order in filteredOrders"
                                        :key="order._id"
                                    >
                                        <td>
                                            <label class="p-2 d-block">
                                                <input
                                                    v-model="formData.orders"
                                                    type="checkbox"
                                                    :value="order._id"
                                                    :id="
                                                        $id(
                                                            `order_${order._id}`
                                                        )
                                                    "
                                                    :disabled="
                                                        selectedOrdersClient &&
                                                        selectedOrdersClient !==
                                                            order.client._id
                                                    "
                                                />
                                            </label>
                                        </td>
                                        <td>
                                            <label
                                                :for="$id(`order_${order._id}`)"
                                                class="d-block py-2"
                                            >
                                                #{{ order.number }}
                                            </label>
                                        </td>
                                        <td>
                                            <label
                                                :for="$id(`order_${order._id}`)"
                                                class="d-block py-2"
                                            >
                                                <status
                                                    :status="order.status"
                                                    class="ml-auto"
                                                />
                                            </label>
                                        </td>
                                        <td>
                                            <label
                                                :for="$id(`order_${order._id}`)"
                                                class="d-block py-2"
                                            >
                                                {{ order.client.name }}
                                            </label>
                                        </td>
                                        <td>
                                            <label
                                                :for="$id(`order_${order._id}`)"
                                                class="d-block py-2"
                                            >
                                                {{
                                                    $t(
                                                        `orderType.${order.type}`
                                                    )
                                                }}
                                            </label>
                                        </td>
                                        <td>
                                            <label
                                                :for="$id(`order_${order._id}`)"
                                                class="d-block py-2"
                                            >
                                                {{ order.date | date }}
                                            </label>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="9999">
                                            Geen orders beschikbaar
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button class="btn btn-primary">
                            Factuur aanmaken
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="card shadow-lg">
            <div class="card-header d-flex align-items-center">
                <h3>Facturen</h3>

                <div class="d-flex ml-auto align-items-center">
                    <p-form-select
                        v-model="clientId"
                        :options="clientOptions"
                        label="Klant"
                        class="mb-0 mr-2 search-input"
                    />
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive-lg">
                    <table class="table mb-0">
                        <thead>
                            <tr>
                                <th>Factuurnummer</th>
                                <th>Status</th>
                                <th>Klant</th>
                                <th>Datum</th>
                                <th>Aantal orders</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(invoice, index) in invoices"
                                :key="`invoice-id-${index}-${invoice._id}`"
                            >
                                <td>#{{ invoice.number }}</td>
                                <td>
                                    <status
                                        :status="invoice.status"
                                        class="ml-auto"
                                    />
                                </td>
                                <td>{{ invoice.clientName }}</td>
                                <td>{{ invoice.date | date }}</td>
                                <td>{{ invoice.orders.length }}</td>
                                <td class="narrow">
                                    <div class="d-flex align-items-center">
                                        <router-link
                                            :to="{
                                                name: 'invoices.edit',
                                                params: { id: invoice._id },
                                            }"
                                            class="btn btn-symbol btn-primary mr-2"
                                            aria-label="Wijzig"
                                        >
                                            <p-icon name="edit" />
                                        </router-link>
                                        <button
                                            @click="handleDelete(invoice._id)"
                                            class="btn btn-symbol btn-secondary"
                                            aria-label="Verwijder"
                                        >
                                            <p-icon name="delete" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

import {
    MUTATION_CREATE_INVOICE,
    MUTATION_DELETE_INVOICE,
    QUERY_INVOICES,
} from '@/graphql/invoice';

import { QUERY_PENDING_ORDERS } from '@/graphql/order';

import { QUERY_CLIENTS } from '@/graphql/client';

export default {
    name: 'InvoicesIndex',
    apollo: {
        clients: QUERY_CLIENTS,
        invoices: QUERY_INVOICES,
        pendingOrders: {
            query: QUERY_PENDING_ORDERS,
            fetchPolicy: 'network-only',
        },
    },
    data() {
        return {
            clientId: '',
            clients: [],
            invoices: [],
            pendingOrders: [],
            formData: {
                client: '',
                date: moment().toISOString(),
                orders: [],
                status: 'PENDING',
            },
        };
    },
    computed: {
        filteredOrders() {
            if (!this.formData.client) return this.pendingOrders;

            return this.pendingOrders.filter(
                (order) => order.client._id === this.formData.client
            );
        },

        clientOptions() {
            if (!this.clients) return [];

            return [
                {
                    label: 'Selecteer',
                    value: '',
                },
                ...this.clients.map((client) => ({
                    label: client.name,
                    value: client._id,
                })),
            ];
        },

        selectedOrdersClient() {
            if (!this.formData.orders.length) return null;

            const id = this.formData.orders[0];
            const order = this.pendingOrders.find(({ _id }) => _id === id);

            return order.client._id;
        },
    },
    methods: {
        async create() {
            const cachedFormData = JSON.parse(JSON.stringify(this.formData));
            cachedFormData.client = this.selectedOrdersClient;

            this.formData = {
                client: '',
                date: moment().toISOString(),
                orders: [],
                status: 'PENDING',
            };

            try {
                await this.$apollo.mutate({
                    mutation: MUTATION_CREATE_INVOICE,
                    variables: {
                        client: cachedFormData.client,
                        date: cachedFormData.date,
                        orders: cachedFormData.orders,
                        status: cachedFormData.status,
                    },
                    update: (store, { data: { createInvoice } }) => {
                        this.clientId = '';

                        this.$router.push({
                            name: 'invoices.edit',
                            params: { id: createInvoice._id },
                        });

                        const queryData = store.readQuery({
                            query: QUERY_INVOICES,
                        });

                        queryData.invoices.push(createInvoice);

                        store.writeQuery({
                            query: QUERY_INVOICES,
                            data: queryData,
                        });
                    },
                    refetchQueries: [
                        { query: QUERY_PENDING_ORDERS },
                        { query: QUERY_INVOICES },
                    ],
                });

                this.$notify({
                    title: 'Factuur is succesvol aangemaakt!',
                    type: 'success',
                    duration: 1000,
                });
            } catch (err) {
                this.formData = cachedFormData;
            }
        },

        async handleDelete(id) {
            await this.confirmModal();

            await this.$apollo.mutate({
                mutation: MUTATION_DELETE_INVOICE,
                variables: { id },
                update: (store) => {
                    this.clientId = '';

                    const queryData = store.readQuery({
                        query: QUERY_INVOICES,
                    });

                    const index = queryData.invoices.findIndex(
                        (invoice) => id === invoice._id
                    );

                    queryData.invoices.splice(index, 1);

                    store.writeQuery({
                        query: QUERY_INVOICES,
                        data: queryData,
                    });
                },
                refetchQueries: [{ query: QUERY_PENDING_ORDERS }],
            });

            this.$notify({
                title: 'Factuur is succesvol verwijderd!',
                type: 'success',
                duration: 1000,
            });
        },
    },
    watch: {
        clientId() {
            this.$apollo.queries.invoices.refetch({
                clientId: this.clientId ? this.clientId : null,
            });
        },
    },
};
</script>
