import gql from 'graphql-tag';

export const QUERY_PRODUCTS = gql`
    query {
        products {
            _id
            title
            variants {
                _id
                description
                price
                quantityType
            }
            vat
        }
    }
`;

export const QUERY_PRODUCT = gql`
    query ($id: ID!) {
        product(id: $id) {
            _id
            title
            variants {
                _id
                description
                price
                quantityType
            }
            vat
        }
    }
`;

export const MUTATION_DELETE_PRODUCT = gql`
    mutation ($id: ID!) {
        deleteProduct(id: $id){
            _id
        }
    }
`;

export const MUTATION_UPDATE_PRODUCT = gql`
    mutation (
        $id: ID!,
        $title: String!,
        $vat: Float!,
    ) {
        updateProduct(
            id: $id,
            title: $title,
            vat: $vat,
        ) {
            _id
            title
            vat
        }
    }
`;

export const MUTATION_CREATE_PRODUCT = gql`
    mutation (
        $title: String!,
        $vat: Float!,
    ) {
        createProduct(
            title: $title,
            vat: $vat,
        ) {
            _id
            title
            variants {
                _id
                description
                price
                quantityType
            }
            vat
        }
    }
`;

export const MUTATION_CREATE_PRODUCTVARIANT = gql`
    mutation (
        $productId: ID!,
        $description: String!,
        $price: Float!,
        $quantityType: QuantityType!,
    ) {
        createProductVariant(
            productId: $productId,
            description: $description,
            price: $price,
            quantityType: $quantityType,
        ) {
            _id
            description
            price
            quantityType
        }
    }
`;


export const MUTATION_UPDATE_PRODUCTVARIANT = gql`
    mutation (
        $id: ID!,
        $description: String!,
        $price: Float!,
        $quantityType: QuantityType!,
    ) {
        updateProductVariant(
            id: $id,
            description: $description,
            price: $price,
            quantityType: $quantityType,
        ) {
            _id
            description
            price
            quantityType
        }
    }
`;

export const MUTATION_DELETE_PRODUCTVARIANT = gql`
    mutation (
        $id: ID!,
    ) {
        deleteProductVariant(
            id: $id,
        ) {
            _id
        }
    }
`;
