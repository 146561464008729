import accounting from 'accounting';
import Vue from 'vue';

import { getDeep, getIcon } from '@23g/pacos/utils';

const currencies = {
    EUR: '€',
};

const decimal = ',';
const precision = 2;
const symbol = '€';
const thousand = '.';

accounting.settings = {
    currency: {
        decimal,
        precision,
        symbol,
        thousand,
        format: {
            pos: '%s %v',
            neg: '%s -%v',
            zero: '%s %v',
        },
    },
    number: {
        decimal,
        precision,
        thousand,
    },
};

Vue.mixin({
    filters: {
        quantityType: (type) => {
            switch (type) {
                case 'KILOGRAM': {
                    return 'Kilogram';
                }
                case 'PIECE': {
                    return 'Stuks';
                }
                case 'PACKAGE':
                default: {
                    return 'Colli';
                }
            }
        },

        perQuantityType(type) {
            switch (type) {
                case 'KILOGRAM': {
                    return 'p/kg';
                }
                case 'PIECE': {
                    return 'p/st';
                }
                case 'PACKAGE':
                default: {
                    return 'p/collo';
                }
            }
        },

        currency: (cents, currency = 'EUR', inputPrecision = precision) => (
            accounting.formatMoney(cents / 100, {
                symbol: currencies[currency],
                precision: inputPrecision,
            })
        ),

        number(number, inputPrecision = precision) {
            return accounting.formatNumber(number, {
                precision: number % 1 === 0 ? 0 : inputPrecision,
            });
        },

        datetime(date) {
            const momentDate = Vue.moment(date);
            const isToday = Vue.moment().isSame(momentDate, 'days');
            const isYesterday = Vue.moment().add(-1, 'days').isSame(momentDate, 'days');

            let format = 'D MMMM YYYY H:m';

            if (isToday) {
                format = '[Vandaag om] H:m';
            } else if (isYesterday) {
                format = '[Gisteren om] H:m';
            } else if (momentDate.isSame(new Date(), 'year')) {
                format = 'dddd D MMMM [om] H:m';
            }

            return momentDate.format(format);
        },

        date(date) {
            const momentDate = Vue.moment(date);
            const isToday = Vue.moment().isSame(momentDate, 'days');
            const isYesterday = Vue.moment().add(-1, 'days').isSame(momentDate, 'days');

            let format = 'D MMMM YYYY';

            if (isToday) {
                format = '[Vandaag]';
            } else if (isYesterday) {
                format = '[Gisteren]';
            } else if (momentDate.isSame(new Date(), 'year')) {
                format = 'dddd D MMMM';
            }

            return momentDate.format(format);
        },
    },
    methods: {
        getDeep,

        formatQuantityType(type) {
            switch (type) {
                case 'KILOGRAM': {
                    return 'Kilogram';
                }
                case 'PIECE': {
                    return 'Stuks';
                }
                case 'PACKAGE':
                default: {
                    return 'Colli';
                }
            }
        },

        getPerQuantityType(type) {
            switch (type) {
                case 'KILOGRAM': {
                    return 'p/kg';
                }
                case 'PIECE': {
                    return 'p/st';
                }
                case 'PACKAGE':
                default: {
                    return 'p/collo';
                }
            }
        },

        confirmModal(message = 'Weet je het zeker?') {
            return new Promise((resolve) => {
                this.$modal.show('dialog', {
                    title: null,
                    text: message,
                    buttons: [
                        {
                            title: 'Doorgaan',
                            default: true,
                            class: 'btn btn-primary',
                            handler: () => {
                                this.$modal.hide('dialog');
                                resolve();
                            },
                        },
                        {
                            class: 'btn btn-link',
                            title: 'Annuleren',
                            handler: () => {
                                this.$modal.hide('dialog');
                            },
                        },
                    ],
                });
            });
        },

        formatDate(date, format) {
            return Vue.moment(date).format(format);
        },

        formatCurrency(cents, currency = 'EUR', inputPrecision = precision) {
            return accounting.formatMoney(cents / 100, {
                symbol: currencies[currency],
                precision: inputPrecision,
            });
        },

        formatNumber(number, inputPrecision = precision) {
            return accounting.formatNumber(parseFloat(number), {
                precision: number % 1 === 0 ? 0 : inputPrecision,
            });
        },

        getIcon(name) {
            return getIcon(name);
        },
    },
});
