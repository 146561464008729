import gql from 'graphql-tag';

export const QUERY_CLIENTS = gql`
    query {
        clients {
            _id
            city
            contactName
            contactPhone
            name
            notes
            postalCode
            street
            streetNumber
        }
    }
`;

export const QUERY_CLIENT = gql`
    query ($id: ID!) {
        client(id: $id) {
            _id
            city
            contactName
            contactPhone
            name
            notes
            postalCode
            street
            streetNumber
        }
    }
`;

export const MUTATION_DELETE_CLIENT = gql`
    mutation ($id: ID!) {
        deleteClient(id: $id){
            _id
        }
    }
`;

export const MUTATION_UPDATE_CLIENT = gql`
    mutation (
        $city: String!,
        $contactName: String!,
        $contactPhone: String!,
        $id: ID!,
        $name: String!,
        $notes: String,
        $postalCode: String!,
        $street: String!,
        $streetNumber: String!,
    ) {
        updateClient(
            city: $city,
            contactName: $contactName,
            contactPhone: $contactPhone,
            id: $id,
            name: $name,
            notes: $notes,
            postalCode: $postalCode,
            street: $street,
            streetNumber: $streetNumber,
        ) {
            _id
            city
            contactName
            contactPhone
            name
            notes
            postalCode
            street
            streetNumber
        }
    }
`;

export const MUTATION_CREATE_CLIENT = gql`
    mutation (
        $city: String!,
        $contactName: String!,
        $contactPhone: String!,
        $name: String!,
        $notes: String,
        $postalCode: String!,
        $street: String!,
        $streetNumber: String!,
    ) {
        createClient(
            city: $city,
            contactName: $contactName,
            contactPhone: $contactPhone,
            name: $name,
            notes: $notes,
            postalCode: $postalCode,
            street: $street,
            streetNumber: $streetNumber,
        ) {
            _id
            city
            contactName
            contactPhone
            name
            notes
            postalCode
            street
            streetNumber
        }
    }
`;
