const state = {
    accessToken: null,
    user: null,
};

const mutations = {
    setAccessToken(state, token) {
        state.accessToken = token;
    },
    setUser(state, user) {
        state.user = user;
    },
};

export default {
    mutations,
    namespaced: true,
    state,
};
