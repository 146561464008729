<template>
    <div
        :class="[
            'badge badge-pill',
            [`status-${lowerCaseStatus}`],
        ]"
        :style="{
            'background-color': $t(`status.${lowerCaseStatus}.color`),
        }"
    >
        {{ $t(`status.${lowerCaseStatus}.text`) }}
    </div>
</template>

<script>
    export default {
        name: 'Status',
        props: {
            status: {
                required: true,
                type: String,
            },
        },
        computed: {
            lowerCaseStatus() {
                return this.status.toLowerCase();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .badge {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        padding: 0 8px;
        vertical-align: middle;
        font-size: 10px;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
        border-radius: 10px;
    }
</style>
