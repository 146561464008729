<template>
    <div
        class="spinner-border text-primary"
        role="status"
    >
        <span class="sr-only">
            Loading...
        </span>
    </div>
</template>

<script>
    export default {
        name: 'Spinner',
    };
</script>
