import Vue from 'vue';

import autocomplete from '@/components/autocomplete';
import back from '@/components/back';
import datepicker from '@/components/datepicker';
import defaultLayout from '@/layouts/default';
import dropdown from '@/components/dropdown';
import modal from '@/components/modal';
import spinner from '@/components/spinner';
import status from '@/components/status';

Vue.component('autocomplete', autocomplete);
Vue.component('back', back);
Vue.component('datepicker', datepicker);
Vue.component('default-layout', defaultLayout);
Vue.component('dropdown', dropdown);
Vue.component('modal', modal);
Vue.component('spinner', spinner);
Vue.component('status', status);
