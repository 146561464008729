<template>
    <div
        class="form-group select-autocomplete has-inline-label"
        :class="[
            parentClass,
            { 'move-label-to-top': moveLabelToTop },
        ]"
    >
        <label
            v-if="label"
            class="form-label"
            :class="{
                'move-label-to-top': moveLabelToTop,
            }"
        >
            {{ label }}
        </label>
        <vue-multiselect
            v-model="selected"
            v-bind="$attrs"
            @select="handleSelect"
            @open="focus = true"
            @close="focus = false"
            :allow-empty="false"
            :custom-label="handleCustomLabel"
            :label="labelKey"
            :loading="loading"
            :options="inputOptions"
            :placeholder="smartPlaceholder"
            :show-labels="false"
            :track-by="valueKey"
            close-on-select
            internal-search
            open-direction="bottom"
        >
            <p slot="noOptions">
                Typ om te zoeken
            </p>

            <p slot="noResult">
                Er zijn geen resultaten gevonden
            </p>

            <div slot="afterList">
                <slot name="afterList" />
            </div>

            <div
                v-if="!selected"
                slot="caret"
                class="multiselect__caret"
            >
                <p-icon name="search" />
            </div>

            <button
                v-if="selected"
                @click="handleClear"
                slot="clear"
                class="multiselect__clear"
                type="button"
                aria-label="Reset"
            >
                <p-icon name="close" />
            </button>

            <template
                slot="option"
                slot-scope="props"
            >
                <slot
                    name="option"
                    :option="props.option"
                />
            </template>
        </vue-multiselect>
        <input
            type="text"
            class="hidden-input"
            :name="name"
            :value="hiddenValue"
            :required="required"
        >
    </div>
</template>

<script>
    import VueMultiselect from 'vue-multiselect';

    export default {
        name: 'SelectAutocomplete',
        inheritAttrs: false,
        components: {
            VueMultiselect,
        },
        props: {
            placeholder: {
                type: String,
                default: '',
            },
            options: {
                type: Array,
                default: () => ([]),
            },
            value: {
                type: [String, Object, Number],
                default: null,
            },
            queryKey: {
                type: String,
                default: 'q',
            },
            label: {
                required: true,
                type: String,
            },
            name: {
                type: String,
                default: 'autocomplete',
            },
            parentClass: {
                type: String,
                default: '',
            },
            customLabel: {
                type: Function,
                default: null,
            },
            returnValueKey: {
                type: String,
                default: '',
            },
            labelKey: {
                type: String,
                default: 'label',
            },
            valueKey: {
                type: String,
                default: 'value',
            },
            required: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                selected: null,
                debounce: null,
                loading: false,
                inputOptions: this.options,
                focus: false,
            };
        },
        computed: {
            hiddenValue() {
                return this.selected[this.valueKey];
            },

            smartPlaceholder() {
                if (this.selected) {
                    return this.handleCustomLabel(this.selected);
                }

                if (this.focus) {
                    return this.placeholder;
                }

                return this.placeholder || this.label;
            },

            moveLabelToTop() {
                return this.placeholder || this.focus || this.selected !== '';
            },
        },
        methods: {
            handleClear() {
                this.selected = '';

                this.$emit('input', '');
            },

            handleSelect(value) {
                if (this.returnValueKey) {
                    this.$emit('input', value[this.valueKey]);
                    return;
                }

                this.$emit('input', { ...value });
            },

            handleCustomLabel(option) {
                if (this.customLabel) {
                    return this.customLabel(option);
                }

                return option[this.labelKey];
            },
        },
        watch: {
            value: {
                immediate: true,
                handler(value) {
                    let selected = value;

                    if (this.returnValueKey && this.value) {
                        const selectedOption = this.inputOptions.find(option => (
                            option[this.returnValueKey] === this.value
                        ));

                        if (selectedOption) selected = selectedOption;
                    }

                    this.selected = selected;
                },
            },
            options(options) {
                this.inputOptions = options;
            },
        },
    };
</script>

<style lang="scss">
    .select-autocomplete {
        &.move-label-to-top {
            .multiselect__tags {
                padding-top: 18px;
                padding-bottom: 6px;
            }
        }

        .form-label {
            z-index: 51;
        }

        .multiselect {
            min-height: 48px;
            color: $body;

            &__caret {
                position: absolute;
                top: 50%;
                right: 16px;
                z-index: 8;
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgba(#000, 0.3);
                pointer-events: none;
                transform: translateY(-50%);

                .icon {
                    font-size: 22px;
                }
            }

            &__tags {
                height: 48px;
                padding: 12px 16px 12px;
                font-size: inherit;
                border-color: $gray;
                border-radius: 0.25rem;
                transition: padding 0.3s;
            }

            &__input,
            &__single,
            &__placeholder {
                display: inline-flex;
                min-height: 22px;
                padding: 0 24px 0 0;
                margin: 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 22px;
                color: inherit;
            }

            &__select {
                &::before {
                    display: none;
                }
            }

            &__clear {
                position: absolute;
                top: 50%;
                right: 18px;
                z-index: 9;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 16px;
                height: 16px;
                font-size: 12px;
                color: rgba(#000, 0.5);
                background: rgba(#000, 0.2);
                border-radius: 50%;
                transform: translateY(-50%);
            }

            &__option {
                white-space: normal;
                line-height: 1.2;

                &--select {
                    font-weight: bold;
                    color: $body;
                    background: #fff;
                }

                &--highlight {
                    color: $body;
                    background: rgba($primary, 0.05);
                }
            }
        }
    }
</style>

<style lang="scss" scoped>
    .hidden-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        opacity: 0;
    }
</style>
