<template>
    <portal to="modal">
        <modal
            v-if="product"
            @close="$router.push({ name: 'products' })"
            size="large"
            :title="`Product '${product.title}'`"
        >
            <div class="card shadow-lg mb-5">
                <div class="card-header">
                    <form
                        @submit.prevent="createProductVariant"
                        action="/"
                        class="row gutter-sm"
                    >
                        <div class="col-lg mb-3 mb-lg-0">
                            <p-form-input
                                v-model="variant.description"
                                label="Beschrijving"
                                parent-class="mb-0"
                                required
                            />
                        </div>

                        <div class="col-lg mb-3 mb-lg-0">
                            <p-form-select
                                v-model="variant.quantityType"
                                label="Eenheid"
                                :options="[
                                    {
                                        label: 'Selecteer',
                                        value: '',
                                    },
                                    {
                                        label: 'Colli',
                                        value: 'PACKAGE',
                                    },
                                    {
                                        label: 'Kilogram',
                                        value: 'KILOGRAM',
                                    },
                                    {
                                        label: 'Stuks',
                                        value: 'PIECE',
                                    },
                                ]"
                                parent-class="mb-0"
                                required
                            />
                        </div>

                        <div class="col-lg mb-3 mb-lg-0">
                            <p-form-input-money
                                v-model="variant.price"
                                label="Prijs"
                                parent-class="mb-0"
                                required
                            />
                        </div>

                        <div class="col-lg-auto d-none d-lg-block">
                            <button
                                class="btn btn-primary btn-symbol btn-lg"
                                aria-label="Toevoegen"
                            >
                                <p-icon name="add" />
                            </button>
                        </div>

                        <div class="col-12 d-lg-none">
                            <button class="btn btn-primary w-100">
                                Toevoegen
                            </button>
                        </div>
                    </form>
                </div>
                <template v-if="productVariants.length">
                    <div
                        v-for="(variant, index) in productVariants"
                        :key="`product-variant-${index}-${variant._id}`"
                        :class="[
                            'card-body',
                            { 'border-bottom': index !== productVariants.length - 1 },
                        ]"
                    >
                        <product-variant
                            @remove="handleRemove"
                            v-model="productVariants[index]"
                        />
                    </div>
                </template>
                <div
                    v-else
                    class="card-body"
                >
                    <small>Nog geen product varianten</small>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <h3>Product informatie</h3>
                </div>
                <div class="card-body">
                    <form
                        @submit.prevent="handleSubmit"
                        action="/"
                    >
                        <p-form-input
                            v-model="formData.title"
                            label="Titel"
                            required
                        />

                        <p-form-input-number
                            v-model="formData.vat"
                            label="BTW"
                            required
                        />

                        <button class="btn btn-primary">
                            Opslaan
                        </button>
                    </form>
                </div>
            </div>
        </modal>
    </portal>
</template>

<script>
    import {
        MUTATION_CREATE_PRODUCTVARIANT,
        MUTATION_UPDATE_PRODUCT,
        QUERY_PRODUCT,
        QUERY_PRODUCTS,
    } from '@/graphql/product';

    import ProductVariant from '@/pages/products/variant';

    export default {
        name: 'ProductEdit',
        components: {
            ProductVariant,
        },
        data() {
            return {
                formData: null,
                productVariants: null,
                variant: {
                    price: '',
                    description: '',
                    quantityType: 'PACKAGE',
                },
            };
        },
        apollo: {
            product: {
                query: QUERY_PRODUCT,
                variables() {
                    return {
                        id: this.$route.params.id,
                    };
                },
                result({ data: { product } }) {
                    this.formData = {
                        title: product.title,
                        vat: product.vat,
                    };

                    this.productVariants = JSON.parse(JSON.stringify(product.variants));
                },
            },
        },
        methods: {
            async handleSubmit() {
                await this.$apollo.mutate({
                    mutation: MUTATION_UPDATE_PRODUCT,
                    variables: {
                        id: this.product._id,
                        title: this.formData.title,
                        vat: +this.formData.vat,
                    },
                    refetchQueries: [{ query: QUERY_PRODUCTS }],
                });

                this.$notify({
                    title: 'Product is succesvol aangepast!',
                    type: 'success',
                    duration: 1000,
                });
            },

            async createProductVariant() {
                await this.$apollo.mutate({
                    mutation: MUTATION_CREATE_PRODUCTVARIANT,
                    variables: {
                        ...this.variant,
                        price: +this.variant.price,
                        productId: this.product._id,
                    },
                    refetchQueries: [{ query: QUERY_PRODUCTS }],
                    update: (store, { data: { createProductVariant } }) => {
                        this.product.variants.push(createProductVariant);
                    },
                });

                this.$notify({
                    title: 'Product variant is succesvol aangemaakt!',
                    type: 'success',
                    duration: 1000,
                });

                this.variant = {
                    price: '',
                    description: '',
                    quantityType: 'PACKAGE',
                };
            },

            handleRemove(id) {
                const index = this.product.variants.findIndex(({ _id }) => _id === id);

                this.product.variants.splice(index, 1);
            },
        },
    };
</script>
