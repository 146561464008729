<template>
    <div v-if="!$apollo.queries.order.loading">
        <div class="d-flex mb-3">
            <back
                v-if="$route.params.invoiceId"
                :to="{
                    name: 'invoices.edit',
                    params: { id: $route.params.invoiceId },
                }"
                class="py-2"
                title="Terug naar factuur"
            />
            <back v-else :to="{ name: 'orders' }" class="py-2" />

            <div class="ml-auto d-flex align-items-center">
                <generate-packing-slip
                    :emballages="emballages"
                    :order="order"
                    :sorted-order-lines="sortedOrderLines"
                    :total-without-vat="totalWithoutVat"
                    :total="total"
                    :vat-total="vatTotal"
                    :vat="vat"
                />
            </div>
        </div>

        <div v-if="isLocked" class="alert alert-warning">
            Let op: de order kan niet meer worden aangepast omdat deze onderdeel
            uitmaakt van een factuur.
        </div>

        <div class="card shadow-lg mb-3">
            <div
                class="card-header border-bottom"
                :class="[{ disabled: isLocked }]"
            >
                <form @submit.prevent="createOrderLine">
                    <div class="row gutter-sm">
                        <div class="col-lg mb-3 mb-lg-0">
                            <autocomplete
                                v-model="newOrderLine.provider"
                                :options="options"
                                label="Product/emballage"
                                parent-class="mb-0"
                                required
                            />
                        </div>
                        <div class="col-lg mb-3 mb-lg-0">
                            <p-form-input-number
                                v-model="newOrderLine.quantity"
                                label="Aantal"
                                parent-class="mb-0"
                                required
                            />
                        </div>
                        <div class="col-lg-auto d-none d-lg-block">
                            <button
                                class="btn btn-primary btn-symbol btn-lg"
                                aria-label="Toevoegen"
                                :disabled="
                                    ['PAID', 'BILLED'].includes(order.status)
                                "
                            >
                                <p-icon name="add" />
                            </button>
                        </div>
                        <div class="col-12 d-lg-none">
                            <button
                                class="btn btn-primary w-100"
                                aria-label="Toevoegen"
                            >
                                Toevoegen
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-body">
                <template v-if="order.lines.length">
                    <div class="table-responsive-lg">
                        <table class="table mb-0">
                            <thead>
                                <tr>
                                    <th>Titel</th>
                                    <th>Beschrijving</th>
                                    <th>Eenheid</th>
                                    <th class="text-right">BTW</th>
                                    <th class="text-right">Aantal</th>
                                    <th class="text-right">Prijs</th>
                                    <th class="text-right">
                                        Totaal <small>(excl.)</small>
                                    </th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template
                                    v-for="(line, index) in sortedOrderLines"
                                >
                                    <order-line
                                        @remove="handleRemove"
                                        v-model="sortedOrderLines[index]"
                                        :key="`line-${index}-${line._id}`"
                                        :order="order"
                                    />
                                </template>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td
                                        colspan="6"
                                        class="text-right font-weight-bold py-3"
                                    >
                                        Subtotaal
                                    </td>
                                    <td class="text-right font-weight-bold">
                                        {{ totalWithoutVat | currency }}
                                    </td>
                                    <td></td>
                                </tr>
                                <tr
                                    v-for="(vat, percentage) in vat"
                                    :key="percentage"
                                >
                                    <td colspan="6" class="text-right py-1">
                                        {{ percentage }}% over
                                        {{ vat.subtotal | currency }}
                                    </td>
                                    <td class="text-right py-1">
                                        {{ vat.vat | currency }}
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td
                                        colspan="6"
                                        class="text-right font-weight-bold py-3"
                                    >
                                        Totaal
                                    </td>
                                    <td
                                        class="text-right font-weight-bold py-3"
                                    >
                                        {{ total | currency }}
                                    </td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </template>
                <small v-else> Nog geen order regels </small>
            </div>
        </div>

        <div class="card shadow-lg">
            <div class="card-header d-flex align-items-center">
                <h3>Order #{{ order.number }}</h3>

                <status :status="order.status" class="ml-auto" />
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-6 d-flex">
                        <div class="alert border flex-grow-1">
                            <h3 class="mb-2">Algemene informatie</h3>

                            <p>
                                {{ order.client.name }}<br />
                                {{ order.client.street }}
                                {{ order.client.streetNumber }}<br />
                                {{ order.client.postalCode }}
                                {{ order.client.city }}
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6 d-flex">
                        <div class="alert border flex-grow-1">
                            <h3 class="mb-2">Contactpersoon</h3>

                            <p>
                                {{ order.client.contactName }}<br />
                                {{ order.client.contactPhone || '-' }}
                            </p>
                        </div>
                    </div>
                </div>

                <div
                    v-if="order.clientNotes"
                    class="alert alert-warning d-flex align-items-center"
                >
                    <p-icon name="warning" class="mr-3" />
                    <div>
                        <h3 class="mb-1">Notitie:</h3>
                        {{ order.clientNotes }}
                    </div>
                </div>
                <fields
                    v-model="formData"
                    @submit="update"
                    submit-text="Wijzigen"
                    edit
                />
            </div>
        </div>
    </div>
</template>

<script>
import {
    QUERY_ORDER,
    QUERY_ORDERS,
    MUTATION_UPDATE_ORDER,
} from '@/graphql/order';
import { QUERY_EMBALLAGES } from '@/graphql/emballage';
import { MUTATION_CREATE_ORDERLINE } from '@/graphql/order-line';
import { QUERY_PRODUCTS } from '@/graphql/product';

import Fields from '@/pages/orders/fields';
import GeneratePackingSlip from '@/pages/orders/generate-packing-slip';
import OrderLine from '@/pages/orders/line';

export default {
    name: 'OrdersEdit',
    components: {
        Fields,
        GeneratePackingSlip,
        OrderLine,
    },
    apollo: {
        emballages: {
            query: QUERY_EMBALLAGES,
        },
        products: {
            query: QUERY_PRODUCTS,
        },
        order: {
            query: QUERY_ORDER,
            variables() {
                return {
                    id: this.$route.params.id,
                };
            },
            result({ data: { order } }) {
                this.formData = {
                    date: order.date,
                    notes: order.notes,
                    status: order.status,
                };
            },
        },
    },
    computed: {
        options() {
            return [...this.productOptions, ...this.emballageOptions];
        },

        isLocked() {
            return ['PAID', 'BILLED'].includes(this.order.status);
        },

        emballageOptions() {
            if (!this.emballages) return [];

            return this.emballages.map((emballage) => ({
                type: 'EMBALLAGE',
                label: `${emballage.title} - ${this.formatCurrency(
                    emballage.price
                )}`,
                value: emballage._id,
            }));
        },

        productOptions() {
            if (!this.products) return [];

            return this.products.reduce(
                (acc, product) => [
                    ...acc,
                    ...[
                        ...product.variants.map((variant) => {
                            const formattedPrice = this.formatCurrency(
                                variant.price
                            );
                            const quantityType = this.getPerQuantityType(
                                variant.quantityType
                            );

                            return {
                                label: `${product.title} - ${variant.description} - ${formattedPrice} ${quantityType}`,
                                product: product._id,
                                type: 'PRODUCT',
                                value: variant._id,
                            };
                        }),
                    ],
                ],
                []
            );
        },

        vat() {
            return this.order.lines.reduce((acc, line) => {
                if (!acc[line.vat]) {
                    acc[line.vat] = {
                        vat: 0,
                        subtotal: 0,
                    };
                }

                acc[line.vat].vat +=
                    ((line.quantity * line.price) / 100) * line.vat;
                acc[line.vat].subtotal += line.quantity * line.price;

                return acc;
            }, {});
        },

        vatTotal() {
            return this.order.lines.reduce(
                (acc, line) =>
                    acc + ((line.price * line.quantity) / 100) * line.vat,
                0
            );
        },

        totalWithoutVat() {
            return this.order.lines.reduce(
                (acc, line) => acc + line.price * line.quantity,
                0
            );
        },

        total() {
            return this.totalWithoutVat + this.vatTotal;
        },

        sortedOrderLines() {
            return Array.prototype.slice.call(this.order.lines).sort((a, b) => {
                if (a.title < b.title) return -1;
                if (a.title > b.title) return 1;
                return 0;
            });
        },
    },
    data() {
        return {
            formData: null,
            newOrderLine: {
                provider: '',
                quantity: '',
            },
        };
    },
    methods: {
        async update() {
            await this.$apollo.mutate({
                mutation: MUTATION_UPDATE_ORDER,
                variables: {
                    id: this.order._id,
                    ...this.formData,
                },
                refetchQueries: [{ query: QUERY_ORDERS }],
            });

            this.$notify({
                title: 'Order is succesvol aangepast!',
                type: 'success',
                duration: 1000,
            });
        },

        async createOrderLine() {
            await this.$apollo.mutate({
                mutation: MUTATION_CREATE_ORDERLINE,
                variables: {
                    order: this.order._id,
                    product: this.newOrderLine.provider.product,
                    provider: this.newOrderLine.provider.value,
                    quantity: +this.newOrderLine.quantity,
                    type: this.newOrderLine.provider.type,
                },
                refetchQueries: [
                    { query: QUERY_ORDER, variables: { id: this.order._id } },
                ],
            });

            this.$notify({
                title: 'Order regel is succesvol toegevoegd!',
                type: 'success',
                duration: 1000,
            });

            this.newOrderLine = {
                provider: '',
                quantity: '',
            };
        },

        handleRemove(id) {
            const index = this.order.lines.findIndex(({ _id }) => _id === id);

            this.order.lines.splice(index, 1);
        },
    },
};
</script>
