import Vue from 'vue';
import VueTippy from 'vue-tippy';

Vue.use(VueTippy, {
    arrow: true,
    arrowType: 'round',
    animateFill: false,
    animation: 'shift-away',
    duration: 250,
});
