<template>
    <div>
        <div class="row">
            <div class="col-lg-8 mb-3 mb-lg-0">
                <div class="card shadow-lg">
                    <div class="card-header">
                        <h3>Klanten</h3>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive-lg">
                            <table class="table mb-0">
                                <thead>
                                    <tr>
                                        <th>Bedrijf</th>
                                        <th>Contactpersoon</th>
                                        <th>Telefoonnummer</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="item in sortedClients"
                                        :key="item._id"
                                    >
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.contactName }}</td>
                                        <td>{{ item.contactPhone }}</td>
                                        <td class="narrow">
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <router-link
                                                    :to="{
                                                        name: 'clients.edit',
                                                        params: {
                                                            id: item._id,
                                                        },
                                                    }"
                                                    class="btn btn-symbol btn-primary mr-2"
                                                    aria-label="Wijzig"
                                                >
                                                    <p-icon name="edit" />
                                                </router-link>
                                                <button
                                                    @click="
                                                        handleDelete(item._id)
                                                    "
                                                    class="btn btn-symbol btn-secondary"
                                                    aria-label="Verwijder"
                                                    disabled
                                                >
                                                    <p-icon name="delete" />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="card shadow-lg">
                    <div class="card-header">
                        <h3>Toevoegen</h3>
                    </div>
                    <div class="card-body">
                        <client-fields
                            v-model="formData"
                            @submit="create"
                            submit-text="Toevoegen"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    QUERY_CLIENTS,
    MUTATION_DELETE_CLIENT,
    MUTATION_CREATE_CLIENT,
} from '@/graphql/client';

import ClientFields from '@/pages/clients/fields';

export default {
    name: 'ClientsIndex',
    components: {
        ClientFields,
    },
    apollo: {
        clients: QUERY_CLIENTS,
    },
    data() {
        return {
            clients: [],
            formData: {
                city: '',
                contactName: '',
                contactPhone: '',
                name: '',
                notes: '',
                postalCode: '',
                street: '',
                streetNumber: '',
            },
        };
    },
    computed: {
        sortedClients() {
            return Array.prototype.slice.call(this.clients).sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });
        },
    },
    methods: {
        async create() {
            const cachedFormData = JSON.parse(JSON.stringify(this.formData));

            this.formData = {
                name: '',
                city: '',
                postalCode: '',
                street: '',
                streetNumber: '',
                contactName: '',
                contactPhone: '',
            };

            try {
                await this.$apollo.mutate({
                    mutation: MUTATION_CREATE_CLIENT,
                    variables: {
                        ...cachedFormData,
                    },
                    update: (store, { data: { createClient } }) => {
                        const queryData = store.readQuery({
                            query: QUERY_CLIENTS,
                        });

                        queryData.clients.push(createClient);

                        store.writeQuery({
                            query: QUERY_CLIENTS,
                            data: queryData,
                        });
                    },
                });

                this.$notify({
                    title: 'Klant is succesvol toegevoegd!',
                    type: 'success',
                    duration: 1000,
                });
            } catch (err) {
                this.formData = cachedFormData;
            }
        },
        async handleDelete(id) {
            await this.confirmModal();

            await this.$apollo.mutate({
                mutation: MUTATION_DELETE_CLIENT,
                variables: { id },
                update: (store) => {
                    const queryData = store.readQuery({
                        query: QUERY_CLIENTS,
                    });

                    const index = queryData.clients.findIndex(
                        (client) => id === client._id
                    );

                    queryData.clients.splice(index, 1);

                    store.writeQuery({
                        query: QUERY_CLIENTS,
                        data: queryData,
                    });
                },
            });

            this.$notify({
                title: 'Klant is succesvol verwijderd!',
                type: 'success',
                duration: 1000,
            });
        },
    },
};
</script>
