<template>
    <router-link :to="to" class="d-flex align-items-center back-button">
        <p-icon name="arrow-back2" class="back-button-icon mr-1" />
        {{ title }}
    </router-link>
</template>

<script>
export default {
    name: 'Back',
    props: {
        to: [Object, String],
        title: {
            type: String,
            default: 'Terug',
        },
    },
};
</script>

<style lang="scss" scoped>
.back-button {
    font-size: 18px;
    font-weight: bold;
    color: $dark;
    text-decoration: none;

    &-icon {
        font-size: 28px;
    }
}
</style>
