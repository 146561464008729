<template>
    <div v-if="!$apollo.queries.emballage.loading">
        <div class="d-flex mb-3">
            <back :to="{ name: 'emballage' }" />
        </div>

        <div class="card shadow-lg">
            <div class="card-header">
                <h3>Wijzig {{ emballage.title }}</h3>
            </div>
            <div class="card-body">
                <form
                    @submit.prevent="update"
                    action="/"
                >
                    <p-form-input
                        v-model="formData.title"
                        label="Titel"
                        required
                    />

                    <p-form-input-money
                        v-model="formData.price"
                        label="Prijs"
                        required
                    />

                    <p-form-input-number
                        v-model="formData.vat"
                        label="BTW"
                        :precision="0"
                        required
                    />

                    <button class="btn btn-primary">
                        Opslaan
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        MUTATION_UPDATE_EMBALLAGE,
        QUERY_EMBALLAGE,
        QUERY_EMBALLAGES,
    } from '@/graphql/emballage';

    export default {
        name: 'EmballageEdit',
        apollo: {
            emballage: {
                query: QUERY_EMBALLAGE,
                variables() {
                    return {
                        id: this.$route.params.id,
                    };
                },
                result({ data: { emballage } }) {
                    this.formData = {
                        price: emballage.price,
                        title: emballage.title,
                        vat: emballage.vat,
                    };
                },
            },
        },
        data() {
            return {
                formData: null,
            };
        },
        methods: {
            async update() {
                await this.$apollo.mutate({
                    mutation: MUTATION_UPDATE_EMBALLAGE,
                    variables: {
                        id: this.emballage._id,
                        price: +this.formData.price,
                        title: this.formData.title,
                        vat: +this.formData.vat,
                    },
                    refetchQueries: [{ query: QUERY_EMBALLAGES }],
                });

                this.$notify({
                    title: 'Emballage is succesvol aangepast!',
                    type: 'success',
                    duration: 1000,
                });
            },
        },
    };
</script>
