<template>
    <div class="site">
        <div class="header bg-dark">
            <div class="content d-flex align-items-center">
                <router-link :to="{ name: 'home' }" class="logo text-white">
                    <img
                        src="/logo.svg"
                        alt="Satisfaction logo"
                        class="logo-image"
                    />
                    <span class="logo-text">Schutter Fruit</span>
                </router-link>

                <div v-if="me" class="d-flex align-items-center ml-auto">
                    <dropdown align="right">
                        <div class="user d-flex" slot="handle">
                            <div>
                                <span class="d-block user-name text-right">
                                    {{ me.username }}
                                </span>
                                <span class="d-block user-role text-right">
                                    {{ $t(`user.role.${me.role}`) }}
                                </span>
                            </div>
                            <p-icon name="chevron-down" class="user-icon" />
                        </div>

                        <div class="list-group">
                            <button
                                @click="handleLogout"
                                class="list-group-item list-group-item-action text-right"
                            >
                                Uitloggen
                            </button>
                        </div>
                    </dropdown>
                </div>
            </div>
        </div>
        <nav class="nav bg-white shadow-sm">
            <div class="table-responsive-lg d-lg-flex flex-lg-grow-1">
                <div class="content">
                    <ul class="nav-list list d-flex align-items">
                        <li class="nav-item">
                            <router-link
                                v-slot="$slot"
                                :to="{ name: 'orders' }"
                                custom
                            >
                                <a
                                    @click="$slot.navigate"
                                    :href="$slot.href"
                                    :class="[
                                        'nav-link',
                                        { active: $slot.isActive },
                                    ]"
                                >
                                    Orders
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                                v-slot="$slot"
                                :to="{ name: 'credit-orders' }"
                                custom
                            >
                                <a
                                    @click="$slot.navigate"
                                    :href="$slot.href"
                                    :class="[
                                        'nav-link',
                                        { active: $slot.isActive },
                                    ]"
                                >
                                    Credit orders
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                                v-slot="$slot"
                                :to="{ name: 'invoices' }"
                                custom
                            >
                                <a
                                    @click="$slot.navigate"
                                    :href="$slot.href"
                                    :class="[
                                        'nav-link',
                                        { active: $slot.isActive },
                                    ]"
                                >
                                    Facturen
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                                v-slot="$slot"
                                :to="{ name: 'products' }"
                                custom
                            >
                                <a
                                    @click="$slot.navigate"
                                    :href="$slot.href"
                                    :class="[
                                        'nav-link',
                                        { active: $slot.isActive },
                                    ]"
                                >
                                    Producten
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                                v-slot="$slot"
                                :to="{ name: 'emballage' }"
                                custom
                            >
                                <a
                                    @click="$slot.navigate"
                                    :href="$slot.href"
                                    :class="[
                                        'nav-link',
                                        { active: $slot.isActive },
                                    ]"
                                >
                                    Emballage
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                                v-slot="$slot"
                                :to="{ name: 'clients' }"
                                custom
                            >
                                <a
                                    @click="$slot.navigate"
                                    :href="$slot.href"
                                    :class="[
                                        'nav-link',
                                        { active: $slot.isActive },
                                    ]"
                                >
                                    Klanten
                                </a>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <div class="content py-3">
            <slot />
            <router-view />
        </div>
    </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapMutations, mapState } from 'vuex';

export default {
    name: 'DefaultLayout',
    apollo: {
        me: {
            query: gql`
                query {
                    me {
                        _id
                        username
                        role
                    }
                }
            `,
        },
    },
    computed: {
        ...mapState('auth', ['user']),
    },
    methods: {
        ...mapMutations('auth', ['setAccessToken', 'setUser']),

        async handleLogout() {
            await this.$apollo.mutate({
                mutation: gql`
                    mutation {
                        revokeOwnRefreshToken
                    }
                `,
            });

            this.$router.push({ name: 'login' });

            this.setAccessToken(null);
            this.setUser(null);
        },
    },
};
</script>

<style lang="scss" scoped>
.logo {
    display: flex;
    align-items: center;
    height: 72px;
    font-family: 'Patua One', cursive;
    font-size: 26px;
    font-weight: normal;
    color: $dark;
    text-decoration: none;

    &-image {
        width: auto;
        height: 50px;
    }

    &-text {
        margin-left: 8px;
    }

    &:hover {
        animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
    }
}

.nav {
    &-item:not(:first-child) {
        margin-left: 24px;
    }

    &-item:last-child {
        padding-right: 16px;
    }

    &-link {
        display: flex;
        padding: 18px 8px 16px;
        font-weight: bold;
        color: $dark;
        text-decoration: none;
        white-space: nowrap;
        border-bottom: 2px solid transparent;
        transition: all 0.3s;

        &:hover {
            color: $secondary;
        }

        &.active {
            color: $secondary;
            border-color: $secondary;
        }
    }
}

.user {
    color: #fff;

    &-name {
        margin-bottom: 4px;
        font-size: 12px;
        font-weight: bold;
        line-height: 12px;
        text-transform: uppercase;
    }

    &-role {
        font-size: 12px;
        line-height: 12px;
        color: rgba(#fff, 0.7);
    }

    &-icon {
        margin-top: -4px;
        font-size: 18px;
        color: #fff;
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0) scale(1) rotate(0deg);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0) scale(1.05) rotate(4deg);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0) scale(1.1) rotate(-2deg);
    }

    40%,
    60% {
        transform: translate3d(2px, 0, 0) scale(1.05) rotate(2deg);
    }
}
</style>
