<template>
    <button @click="openInvoice" class="btn btn-info d-flex align-items-center">
        <p-icon name="download2" class="icon-download mr-1" />
        Factuur
    </button>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake';

import pdfFonts from '@/assets/fonts/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    NunitoSans: {
        normal: 'NunitoSans-Regular.ttf',
        bold: 'NunitoSans-Bold.ttf',
        italics: 'NunitoSans-Italic.ttf',
        bolditalics: 'NunitoSans-BoldItalic.ttf',
    },
    PatuaOne: {
        normal: 'PatuaOne-Regular.ttf',
        bold: 'PatuaOne-Regular.ttf',
    },
};

export default {
    name: 'GenerateInvoice',
    props: ['orders', 'total', 'subtotal', 'vat', 'client', 'invoice'],
    methods: {
        openInvoice() {
            pdfMake
                .createPdf({
                    pageMargins: [40, 80, 40, 140],
                    header: (currentPage, pageCount) => ({
                        columns: [
                            {
                                text: 'Schutter Fruit',
                                margin: [40, 24, 0, 0],
                                font: 'PatuaOne',
                                fontSize: 24,
                                bold: true,
                            },
                            {
                                text: `${currentPage} / ${pageCount}`,
                                margin: [0, 32, 40, 0],
                                fontSize: 10,
                                alignment: 'right',
                            },
                        ],
                    }),
                    content: [
                        {
                            text: this.client.name,
                            style: 'clientAddressLine',
                        },
                        {
                            text: `${this.client.street} ${this.client.streetNumber}`,
                            style: 'clientAddressLine',
                        },
                        {
                            text: `${this.client.postalCode} ${this.client.city}`,
                            style: 'clientAddressLine',
                            margin: [0, 0, 0, 32],
                        },
                        {
                            text: 'Factuur',
                            fontSize: 18,
                            bold: true,
                            margin: [0, 0, 0, 8],
                        },
                        {
                            columns: [
                                {
                                    text: 'Factuurnummer:',
                                    width: 90,
                                    bold: true,
                                },
                                {
                                    text: this.invoice.number,
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    text: 'Factuurdatum:',
                                    width: 90,
                                    bold: true,
                                },
                                {
                                    text: this.$moment(
                                        this.invoice.date
                                    ).format('DD-MM-YYYY'),
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    text: 'Vervaldatum:',
                                    width: 90,
                                    bold: true,
                                },
                                {
                                    text: this.$moment(this.invoice.date)
                                        .add(7, 'd')
                                        .format('DD-MM-YYYY'),
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    text: 'Notitie:',
                                    width: 90,
                                    bold: true,
                                },
                                {
                                    text: this.invoice.notes,
                                },
                            ],
                            margin: [0, 0, 0, 32],
                        },
                        {
                            style: 'table',
                            layout: 'lightHorizontalLines',
                            table: {
                                headerRows: 1,
                                widths: ['auto', '*', 'auto'],
                                body: [
                                    [
                                        {
                                            text: 'Ordernummer'.toUpperCase(),
                                            bold: true,
                                            border: [false],
                                        },
                                        {
                                            text: 'Datum'.toUpperCase(),
                                            bold: true,
                                            border: [false],
                                        },
                                        {
                                            text: ''.toUpperCase(),
                                            bold: true,
                                            border: [false],
                                        },
                                    ],
                                    ...this.orders.map((order) => [
                                        {
                                            text: `#${order.number}`,
                                            margin: [0, 2],
                                        },
                                        {
                                            text: this.$moment(
                                                order.date
                                            ).format('DD-MM-YYYY'),
                                            margin: [0, 2],
                                        },
                                        {
                                            text: this.formatCurrency(
                                                order.subtotal
                                            ),
                                            alignment: 'right',
                                            margin: [0, 2],
                                            noWrap: true,
                                        },
                                    ]),
                                    [
                                        {
                                            colSpan: 2,
                                            text: 'Subtotaal',
                                            border: [true, false, false, false],
                                            alignment: 'right',
                                            margin: [0, 3, 0, 0],
                                            bold: true,
                                        },
                                        '',
                                        {
                                            text: this.formatCurrency(
                                                this.subtotal
                                            ),
                                            border: [false, false, true, false],
                                            alignment: 'right',
                                            margin: [0, 3, 0, 0],
                                            noWrap: true,
                                            bold: true,
                                        },
                                    ],
                                    ...Object.keys(this.vat).map((vat) => [
                                        {
                                            colSpan: 2,
                                            text: `${vat}% over ${this.formatCurrency(
                                                this.vat[vat].subtotal
                                            )}`,
                                            border: [true, false, false, false],
                                            alignment: 'right',
                                        },
                                        '',
                                        {
                                            text: this.formatCurrency(
                                                this.vat[vat].vat
                                            ),
                                            border: [false, false, true, false],
                                            alignment: 'right',
                                            noWrap: true,
                                        },
                                    ]),
                                    [
                                        {
                                            colSpan: 2,
                                            text: 'Totaal',
                                            border: [true, false, false, true],
                                            alignment: 'right',
                                            bold: true,
                                            margin: [0, 0, 0, 3],
                                        },
                                        '',
                                        {
                                            text: this.formatCurrency(
                                                this.total
                                            ),
                                            border: [false, false, true, true],
                                            alignment: 'right',
                                            bold: true,
                                            noWrap: true,
                                            margin: [0, 0, 0, 3],
                                        },
                                    ],
                                ],
                            },
                        },
                        {
                            text: `Wij verzoeken u vriendelijk ${this.formatCurrency(
                                this.total
                            )} binnen 14 dagen te voldoen op onze bankrekening
                                NL90RABO0320509230 onder vermelding van het factuurnummer ${
                                    this.invoice.number
                                }`,
                            lineHeight: 1.2,
                            margin: [0, 24, 0, 0],
                        },
                    ],
                    footer: [
                        {
                            text: 'Schutter Fruit',
                            font: 'PatuaOne',
                            fontSize: 18,
                            alignment: 'center',
                            bold: true,
                            lineHeight: 1.4,
                            margin: [0, 40, 0, 0],
                        },
                        {
                            text: "Oude rijksweg 69a, 4472 AE 's-Heer Hendrikskinderen",
                            style: ['footerAddress'],
                        },
                        {
                            columns: [
                                {
                                    width: '*',
                                    text: '',
                                },
                                {
                                    width: 'auto',
                                    text: 'KVK:',
                                    style: ['footerInfoTitle'],
                                },
                                {
                                    width: 4,
                                    text: '',
                                },
                                {
                                    width: 'auto',
                                    text: '22011764',
                                    style: ['footerInfo'],
                                },
                                {
                                    width: 12,
                                    text: '',
                                },
                                {
                                    width: 'auto',
                                    text: 'BTW NR.:',
                                    style: ['footerInfoTitle'],
                                },
                                {
                                    width: 4,
                                    text: '',
                                },
                                {
                                    width: 'auto',
                                    text: 'NL803306131B01',
                                    style: ['footerInfo'],
                                },
                                {
                                    width: 12,
                                    text: '',
                                },
                                {
                                    width: 'auto',
                                    text: 'IBAN:',
                                    style: ['footerInfoTitle'],
                                },
                                {
                                    width: 4,
                                    text: '',
                                },
                                {
                                    width: 'auto',
                                    text: 'NL90RABO0320509230',
                                    style: ['footerInfo'],
                                },
                                {
                                    width: '*',
                                    text: '',
                                },
                            ],
                        },
                    ],
                    defaultStyle: {
                        fontSize: 10,
                        font: 'NunitoSans',
                    },
                    styles: {
                        title: {
                            fontSize: 16,
                            bold: true,
                        },
                        footerAddress: {
                            alignment: 'center',
                            lineHeight: 1.2,
                        },
                        footerInfoTitle: {
                            alignment: 'center',
                            bold: true,
                            margin: [0, 4],
                            lineHeight: 1.2,
                        },
                        footerInfo: {
                            alignment: 'center',
                            margin: [0, 4],
                            lineHeight: 1.2,
                        },
                        table: {
                            fontSize: 10,
                        },
                    },
                })
                .open();
        },
    },
};
</script>

<style lang="scss" scoped>
.icon-download {
    font-size: 20px;
}
</style>
