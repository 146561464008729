import Vue from 'vue';
import VueI18n from 'vue-i18n';

import messages from '@/lang/nl';

Vue.use(VueI18n, {
    locale: 'nl',
    fallbackLocale: 'nl',
    messages,
})

export default new VueI18n({
    locale: 'nl',
    fallbackLocale: 'nl',
    messages,
});
