<template>
    <div
        class="dropdown"
        :class="{ show }"
        v-click-outside="handleOutsideClick"
    >
        <button
            @click="show = !show"
            type="button"
            class="dropdown-handle"
        >
            <slot name="handle"/>
        </button>
        <transition name="slide-fade">
            <div
                v-if="show"
                class="dropdown-panel"
                :class="[`align-${align}`]"
            >
                <slot/>
            </div>
        </transition>
    </div>
</template>

<script>
    import * as vClickOutside from 'v-click-outside-x';

    export default {
        name: 'Dropdown',
        directives: {
            clickOutside: vClickOutside.directive,
        },
        props: {
            align: {
                type: String,
                default: 'left',
            },
        },
        data() {
            return {
                show: false,
            };
        },
        methods: {
            handleOutsideClick() {
                this.show = false;
            },
        },
    };
</script>

<style lang="scss">
    .dropdown {
        position: relative;
        z-index: 100;

        &.show {
            z-index: 101;

            .dropdown-handle .icon-chevron-down {
                transform: scaleY(-1);
            }
        }

        &-panel {
            position: absolute;
            top: 100%;
            left: 0;
            width: auto;
            margin-top: 8px;
            overflow: hidden;
            background: #fff;
            border-radius: 4px;
            box-shadow: 0 0 48px rgba(#000, 0.15);

            &.align-right {
                right: 0;
                left: auto;
                margin-right: -8px;
                margin-left: 0;
            }

            &-hidden {
                .dropdown:not(.show) & {
                    position: absolute;
                    width: 1px;
                    height: 1px;
                    padding: 0;
                    margin: -1px;
                    overflow: hidden;
                    clip: rect(0, 0, 0, 0);
                    white-space: nowrap;
                    border-width: 0;
                }
            }
        }

        &-handle .icon-chevron-down {
            transition: transform 0.3s;
        }
    }
</style>
