var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"datepicker form-group has-inline-label",class:[
        {
            'has-placeholder': !!_vm.placeholder,
        },
        _vm.parentClass ]},[_c('label',{class:[
            'form-label',
            {
                'move-label-to-top': _vm.moveLabelToTop,
            } ],attrs:{"for":_vm.uniqueId}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"input-group"},[_c('v-date-picker',_vm._b({class:[
                {
                    readonly: _vm.readonly,
                    disabled: _vm.disabled,
                    'move-label-to-top': _vm.moveLabelToTop,
                },
                'form-control' ],attrs:{"formats":{
                input: 'DD-MM-YYYY',
            },"attributes":[
                {
                    key: 'today',
                    dates: new Date(),
                    highlight: {
                        backgroundColor: '#e5e5e5',
                    },
                    contentStyle: {
                        color: '#1a2a38',
                    },
                    popover: {
                        label: 'Today',
                    },
                } ],"mode":"single"},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}},'v-date-picker',_vm.$attrs,false))],1),_c('input',{staticClass:"datepicker-input",attrs:{"type":"text","id":_vm.uniqueId,"name":_vm.name,"required":_vm.required},domProps:{"value":_vm.ISOString}})])}
var staticRenderFns = []

export { render, staticRenderFns }