var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"site"},[_c('div',{staticClass:"header bg-dark"},[_c('div',{staticClass:"content d-flex align-items-center"},[_c('router-link',{staticClass:"logo text-white",attrs:{"to":{ name: 'home' }}},[_c('img',{staticClass:"logo-image",attrs:{"src":"/logo.svg","alt":"Satisfaction logo"}}),_c('span',{staticClass:"logo-text"},[_vm._v("Schutter Fruit")])]),(_vm.me)?_c('div',{staticClass:"d-flex align-items-center ml-auto"},[_c('dropdown',{attrs:{"align":"right"}},[_c('div',{staticClass:"user d-flex",attrs:{"slot":"handle"},slot:"handle"},[_c('div',[_c('span',{staticClass:"d-block user-name text-right"},[_vm._v(" "+_vm._s(_vm.me.username)+" ")]),_c('span',{staticClass:"d-block user-role text-right"},[_vm._v(" "+_vm._s(_vm.$t(("user.role." + (_vm.me.role))))+" ")])]),_c('p-icon',{staticClass:"user-icon",attrs:{"name":"chevron-down"}})],1),_c('div',{staticClass:"list-group"},[_c('button',{staticClass:"list-group-item list-group-item-action text-right",on:{"click":_vm.handleLogout}},[_vm._v(" Uitloggen ")])])])],1):_vm._e()],1)]),_c('nav',{staticClass:"nav bg-white shadow-sm"},[_c('div',{staticClass:"table-responsive-lg d-lg-flex flex-lg-grow-1"},[_c('div',{staticClass:"content"},[_c('ul',{staticClass:"nav-list list d-flex align-items"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":{ name: 'orders' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function($slot){return [_c('a',{class:[
                                    'nav-link',
                                    { active: $slot.isActive } ],attrs:{"href":$slot.href},on:{"click":$slot.navigate}},[_vm._v(" Orders ")])]}}])})],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":{ name: 'credit-orders' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function($slot){return [_c('a',{class:[
                                    'nav-link',
                                    { active: $slot.isActive } ],attrs:{"href":$slot.href},on:{"click":$slot.navigate}},[_vm._v(" Credit orders ")])]}}])})],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":{ name: 'invoices' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function($slot){return [_c('a',{class:[
                                    'nav-link',
                                    { active: $slot.isActive } ],attrs:{"href":$slot.href},on:{"click":$slot.navigate}},[_vm._v(" Facturen ")])]}}])})],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":{ name: 'products' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function($slot){return [_c('a',{class:[
                                    'nav-link',
                                    { active: $slot.isActive } ],attrs:{"href":$slot.href},on:{"click":$slot.navigate}},[_vm._v(" Producten ")])]}}])})],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":{ name: 'emballage' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function($slot){return [_c('a',{class:[
                                    'nav-link',
                                    { active: $slot.isActive } ],attrs:{"href":$slot.href},on:{"click":$slot.navigate}},[_vm._v(" Emballage ")])]}}])})],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":{ name: 'clients' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function($slot){return [_c('a',{class:[
                                    'nav-link',
                                    { active: $slot.isActive } ],attrs:{"href":$slot.href},on:{"click":$slot.navigate}},[_vm._v(" Klanten ")])]}}])})],1)])])])]),_c('div',{staticClass:"content py-3"},[_vm._t("default"),_c('router-view')],2)])}
var staticRenderFns = []

export { render, staticRenderFns }