<template>
    <form @submit.prevent="$emit('submit')" action="/">
        <p-form-select
            v-if="canEditClient"
            v-model="value.client"
            :options="clientOptions"
            label="Klant"
            required
        />

        <p-form-textarea v-model="value.notes" label="Referentie/notitie" />

        <p-form-select
            v-model="value.status"
            label="Status"
            :options="[
                {
                    label: 'Selecteer',
                    value: '',
                },
                {
                    label: this.$t('status.ready.text'),
                    value: 'READY',
                },
            ]"
            required
        />

        <datepicker v-model="value.date" label="Datum" required />

        <button class="btn btn-primary">
            {{ submitText }}
        </button>
    </form>
</template>

<script>
import { QUERY_CLIENTS } from '@/graphql/client';

export default {
    name: 'CreditOrdersFields',
    apollo: {
        clients: {
            query: QUERY_CLIENTS,
        },
    },
    props: {
        submitText: {
            type: String,
            default: 'Opslaan',
        },
        value: {
            required: true,
            type: Object,
        },
        canEditClient: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        clientOptions() {
            if (!this.clients) return [];

            return [
                {
                    label: 'Selecteer',
                    value: '',
                },
                ...this.clients.map((client) => ({
                    label: client.name,
                    value: client._id,
                })),
            ];
        },
    },
};
</script>
