import gql from 'graphql-tag';

export const QUERY_EMBALLAGES = gql`
    query {
        emballages {
            _id
            price
            title
            vat
        }
    }
`;

export const QUERY_EMBALLAGE = gql`
    query ($id: ID!) {
        emballage(id: $id) {
            _id
            price
            title
            vat
        }
    }
`;

export const MUTATION_DELETE_EMBALLAGE = gql`
    mutation ($id: ID!) {
        deleteEmballage(id: $id){
            _id
        }
    }
`;

export const MUTATION_UPDATE_EMBALLAGE = gql`
    mutation (
        $id: ID!,
        $title: String!,
        $price: Float!,
        $vat: Int!,
    ) {
        updateEmballage(
            id: $id,
            title: $title,
            price: $price,
            vat: $vat,
        ){
            _id
            price
            title
            vat
        }
    }
`;

export const MUTATION_CREATE_EMBALLAGE = gql`
    mutation (
        $title: String!,
        $price: Float!,
        $vat: Int!,
    ) {
        createEmballage(
            title: $title,
            price: $price,
            vat: $vat,
        ){
            _id
            title
            price
            vat
        }
    }
`;
