<template>
    <transition name="fade" appear>
        <div
            tabindex="-1"
            class="custom-modal animate-in"
            :class="[size]"
        >
            <div
                @click="close"
                class="custom-modal-bg"
            ></div>
            <div class="custom-modal-box">
                <div
                    v-if="$slots.header || title"
                    class="custom-modal-box-header"
                >
                    <slot name="pre-header" />

                    <h2
                        v-if="title"
                        class="custom-modal-title mb-0"
                    >
                        {{ title }}
                    </h2>

                    <slot name="header" />

                    <button
                        v-if="closable"
                        @click="close"
                        aria-label="Sluiten"
                        class="custom-modal-close"
                    >
                        <p-icon name="close" />
                    </button>
                </div>

                <div class="custom-modal-box-content">
                    <slot/>
                </div>

                <div
                    v-if="$slots.footer"
                    class="custom-modal-box-footer"
                >
                    <slot name="footer"/>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'Modal',
        data() {
            return {
                mainContent: document.querySelector('.app-content'),
            };
        },
        props: {
            title: {
                required: false,
                type: String,
                default: null,
            },
            closable: {
                required: false,
                default: true,
                type: Boolean,
            },
            size: {
                required: false,
                default: 'medium',
                type: String,
            },
            customClosing: {
                type: Boolean,
                default: false,
            },
        },
        async mounted() {
            await this.$nextTick();

            this.mainContent.querySelectorAll('input, textarea, select').forEach((element) => {
                element.dataset.tabIndex = element.tabIndex;
                element.tabIndex = '-1';
            });

            this.mainContent.style.top = -window.pageYOffset + 'px';
            document.body.classList.add('custom-modal-open');

            document.addEventListener('keyup', (e) => {
                if (e.keyCode === 27) this.close();
            });
        },
        destroyed() {
            this.mainContent.querySelectorAll('input, textarea, select').forEach((element) => {
                element.tabIndex = element.dataset.tabIndex;
                delete element.dataset.tabIndex;
            });

            const scrollOffset = Math.abs(parseFloat(this.mainContent.style.top));

            document.body.classList.remove('custom-modal-open');
            this.mainContent.style.removeProperty('top');
            window.scrollTo(0, scrollOffset);
            document.removeEventListener('keyup', this.close);
        },
        methods: {
            close() {
                if (!this.closable) return;

                this.$emit('close');
            },
        },
    };
</script>

<style lang="scss">
    body,
    html,
    #app {
        height: 100%;
    }

    .custom-modal {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 100;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 100%;

        &-button {
            flex-grow: 1;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }

        &-back-button {
            display: inline-flex;
            align-items: center;
            padding: 0 16px;
            margin-bottom: 16px;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            white-space: nowrap;
            border-radius: 12px;
        }

        &-divider {
            height: 1px;
            margin: 30px -32px;
            background: rgba(#000, 0.2);
        }

        &-open .app-content {
            position: fixed;
            display: flex;
            width: 100%;

            > * {
                flex: 1;
            }
        }

        &-bg {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background: rgba(#000, 0.7);
        }

        &-box {
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            width: calc(100% - 16px);
            margin: 8px;
            background: #fff;
            border-radius: 5px;
            box-shadow: 1px 1px 2px rgba(#000, 0.05);

            @include respond-to(lg) {
                flex-grow: 0;
                max-width: 600px;
                margin: 32px auto;

                .large & {
                    width: calc(100% - 64px);
                    max-width: 1000px;
                }

                .huge & {
                    width: calc(100% - 64px);
                    max-width: 1600px;
                }

                .small & {
                    width: calc(100% - 64px);
                    max-width: 400px;
                }
            }

            &-header {
                position: relative;
                padding: 16px 44px 16px 16px;
                border-bottom: 1px solid rgba(#000, 0.05);

                @include respond-to(lg) {
                    padding: 24px 46px 24px 32px;
                }
            }

            &-content {
                flex: 1 1 auto;
                padding: 16px;

                @include respond-to(lg) {
                    padding: 32px;
                }
            }

            &-footer {
                padding: 16px;
                background: rgba(#000, 0.1);
                border-radius: 0 0 5px 5px;

                @include respond-to(lg) {
                    padding: 32px;
                }
            }

            .animate-in & {
                animation: animateInModalBox 0.3s 0.2s both;
            }
        }

        &-close {
            position: absolute;
            top: 8px;
            right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            font-size: 24px;
            line-height: 48px;
            text-align: center;
            transition: color 0.3s;

            @include respond-to(lg) {
                top: 16px;
                right: 16px;
            }
        }
    }

    @keyframes animateInModalBox {
        from {
            opacity: 0;
            transform: scale(0.8);
        }
        to {
            opacity: 1;
            transform: scale(1);
        }
    }
</style>
