<template>
    <form @submit.prevent="$emit('submit')" action="/">
        <p-form-select
            v-if="canEditClient"
            v-model="value.client"
            :options="clientOptions"
            label="Klant"
            :disabled="isLocked"
            required
        />

        <p-form-select
            v-if="!isLocked"
            v-model="value.status"
            label="Status"
            :options="[
                {
                    label: 'Selecteer',
                    value: '',
                },
                {
                    label: this.$t('status.pending.text'),
                    value: 'PENDING',
                },
                {
                    label: this.$t('status.ready.text'),
                    value: 'READY',
                },
            ]"
            required
        />

        <p-form-textarea v-model="value.notes" label="Referentie/notitie" />

        <datepicker v-model="value.date" label="Datum" readonly required />

        <button class="btn btn-primary" :disabled="isLocked">
            {{ submitText }}
        </button>
    </form>
</template>

<script>
import { QUERY_CLIENTS } from '@/graphql/client';

export default {
    name: 'OrderFields',
    apollo: {
        clients: {
            query: QUERY_CLIENTS,
        },
    },
    props: {
        submitText: {
            type: String,
            default: 'Opslaan',
        },
        value: {
            required: true,
            type: Object,
        },
        canEditClient: {
            type: Boolean,
            default: false,
        },
        edit: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isLocked() {
            return ['PAID', 'BILLED'].includes(this.value.status);
        },

        clientOptions() {
            if (!this.clients) return [];

            return [
                {
                    label: 'Selecteer',
                    value: '',
                },
                ...this.clients.map((client) => ({
                    label: client.name,
                    value: client._id,
                })),
            ];
        },
    },
};
</script>
