import gql from 'graphql-tag';

export const QUERY_ORDERS = gql`
    query ($clientId: ID) {
        orders(clientId: $clientId) {
            _id
            createdAt
            date
            notes
            number
            status
            type
            clientNotes
            client {
                _id
                city
                contactName
                contactPhone
                name
                notes
                postalCode
                street
                streetNumber
            }
            type
        }
    }
`;

export const QUERY_PENDING_ORDERS = gql`
    query {
        pendingOrders {
            _id
            createdAt
            date
            notes
            number
            status
            type
            clientNotes
            client {
                _id
                city
                contactName
                contactPhone
                name
                notes
                postalCode
                street
                streetNumber
            }
            type
        }
    }
`;

export const QUERY_CREDIT_ORDERS = gql`
    query ($clientId: ID) {
        creditOrders(clientId: $clientId) {
            _id
            createdAt
            date
            notes
            number
            status
            clientNotes
            client {
                _id
                city
                contactName
                contactPhone
                name
                notes
                postalCode
                street
                streetNumber
            }
            type
        }
    }
`;

export const QUERY_ORDER = gql`
    query ($id: ID!) {
        order(id: $id) {
            _id
            createdAt
            date
            notes
            number
            status
            type
            clientNotes
            client {
                _id
                city
                contactName
                contactPhone
                name
                notes
                postalCode
                street
                streetNumber
            }
            lines {
                _id
                description
                price
                quantity
                quantityType
                title
                type
                vat
            }
            type
        }
    }
`;

export const MUTATION_CREATE_ORDER = gql`
    mutation (
        $status: OrderStatus!
        $client: ID!
        $date: String!
        $notes: String
        $type: OrderType!
    ) {
        createOrder(
            status: $status
            client: $client
            date: $date
            notes: $notes
            type: $type
        ) {
            _id
            createdAt
            date
            notes
            number
            status
            type
            clientNotes
            client {
                _id
                city
                contactName
                contactPhone
                name
                notes
                postalCode
                street
                streetNumber
            }
            lines {
                _id
            }
            type
        }
    }
`;

export const MUTATION_UPDATE_ORDER = gql`
    mutation ($status: OrderStatus!, $date: String!, $id: ID!, $notes: String) {
        updateOrder(status: $status, date: $date, id: $id, notes: $notes) {
            _id
            createdAt
            date
            notes
            number
            status
            type
            clientNotes
            client {
                _id
                city
                contactName
                contactPhone
                name
                notes
                postalCode
                street
                streetNumber
            }
            lines {
                _id
                description
                price
                quantity
                quantityType
                title
                type
                vat
            }
            type
        }
    }
`;

export const MUTATION_DELETE_ORDER = gql`
    mutation ($id: ID!) {
        deleteOrder(id: $id)
    }
`;
