<template>
    <div class="row">
        <div class="col-lg-8 mb-3 mb-lg-0">
            <div class="card shadow-lg">
                <div class="card-header d-flex align-items-center">
                    <h3>Creditfacturen</h3>

                    <div class="d-flex ml-auto align-items-center">
                        <p-form-select
                            v-model="clientId"
                            :options="clientOptions"
                            label="Klant"
                            class="mb-0 mr-2 search-input"
                        />
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive-lg">
                        <table class="table mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Status</th>
                                    <th>Klant</th>
                                    <th>Datum</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(order, index) in sortedOrders"
                                    :key="`order-id-${index}-${order._id}`"
                                >
                                    <td>#{{ order.number }}</td>
                                    <td>
                                        <status
                                            :status="order.status"
                                            class="ml-auto"
                                        />
                                    </td>
                                    <td>{{ order.client.name }}</td>
                                    <td>{{ order.date | date }}</td>
                                    <td class="narrow">
                                        <div class="d-flex align-items-center">
                                            <router-link
                                                :to="{
                                                    name: 'credit-orders.edit',
                                                    params: { id: order._id },
                                                }"
                                                class="btn btn-symbol btn-primary mr-2"
                                                aria-label="Wijzig"
                                            >
                                                <p-icon name="edit" />
                                            </router-link>
                                            <button
                                                @click="handleDelete(order._id)"
                                                :disabled="
                                                    ['PAID', 'BILLED'].includes(
                                                        order.status
                                                    )
                                                "
                                                class="btn btn-symbol btn-secondary"
                                                aria-label="Verwijder"
                                            >
                                                <p-icon name="delete" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="card shadow-lg">
                <div class="card-header">
                    <h3>Creditfactuur toevoegen</h3>
                </div>
                <div class="card-body">
                    <fields
                        @submit="create"
                        v-model="formData"
                        submit-text="Toevoegen"
                        can-edit-client
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

import { QUERY_CLIENTS } from '@/graphql/client';

import {
    MUTATION_CREATE_ORDER,
    MUTATION_DELETE_ORDER,
    QUERY_CREDIT_ORDERS,
} from '@/graphql/order';

import Fields from '@/pages/credit-orders/fields';

export default {
    name: 'CreditOrdersIndex',
    components: {
        Fields,
    },
    apollo: {
        clients: QUERY_CLIENTS,
        creditOrders: {
            query: QUERY_CREDIT_ORDERS,
            variables() {
                return {
                    type: 'CREDIT',
                };
            },
        },
    },
    data() {
        return {
            clientId: '',
            creditOrders: [],
            formData: {
                client: '',
                date: moment().toISOString(),
                notes: '',
                status: 'READY',
            },
        };
    },
    computed: {
        sortedOrders() {
            return Array.prototype.slice
                .call(this.creditOrders)
                .sort((a, b) => b.number - a.number);
        },

        clientOptions() {
            if (!this.clients) return [];

            return [
                {
                    label: 'Toon alles',
                    value: '',
                },
                ...this.clients.map((client) => ({
                    label: client.name,
                    value: client._id,
                })),
            ];
        },
    },
    methods: {
        async create() {
            const cachedFormData = JSON.parse(JSON.stringify(this.formData));

            this.formData = {
                client: '',
                date: moment().toISOString(),
                notes: '',
                status: 'READY',
            };

            try {
                await this.$apollo.mutate({
                    mutation: MUTATION_CREATE_ORDER,
                    variables: {
                        client: cachedFormData.client,
                        date: cachedFormData.date,
                        notes: cachedFormData.notes,
                        status: cachedFormData.status,
                        type: 'CREDIT',
                    },
                    update: (store, { data: { createOrder } }) => {
                        this.clientId = '';

                        this.$router.push({
                            name: 'credit-orders.edit',
                            params: { id: createOrder._id },
                        });

                        const queryData = store.readQuery({
                            query: QUERY_CREDIT_ORDERS,
                        });

                        queryData.creditOrders.push(createOrder);

                        store.writeQuery({
                            query: QUERY_CREDIT_ORDERS,
                            data: queryData,
                        });
                    },
                });

                this.$notify({
                    title: 'Creditfactuur is succesvol aangemaakt!',
                    type: 'success',
                    duration: 1000,
                });
            } catch (err) {
                this.formData = cachedFormData;
            }
        },

        async handleDelete(id) {
            await this.confirmModal();

            await this.$apollo.mutate({
                mutation: MUTATION_DELETE_ORDER,
                variables: { id },
                update: (store) => {
                    this.clientId = '';

                    const queryData = store.readQuery({
                        query: QUERY_CREDIT_ORDERS,
                    });

                    const index = queryData.creditOrders.findIndex(
                        (order) => id === order._id
                    );

                    queryData.creditOrders.splice(index, 1);

                    store.writeQuery({
                        query: QUERY_CREDIT_ORDERS,
                        data: queryData,
                    });
                },
            });

            this.$notify({
                title: 'Creditfactuur is succesvol verwijderd!',
                type: 'success',
                duration: 1000,
            });
        },
    },
    watch: {
        clientId() {
            this.$apollo.queries.creditOrders.refetch({
                clientId: this.clientId ? this.clientId : null,
            });
        },
    },
};
</script>
