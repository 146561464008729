<template>
    <div id="app">
        <div class="app-content h-100">
            <template v-if="isLoading">
                <div class="d-flex align-items-center justify-content-center h-100">
                    <spinner />
                </div>
            </template>
            <template v-else>
                <router-view />
            </template>
        </div>

        <portal-target name="modal" />

        <v-dialog width="300px" />

        <notifications
            position="top center"
            width="100%"
            :max="1"
            :duration="2000"
            class="notifications"
        >
            <template
                slot="body"
                slot-scope="props"
            >
                <div
                    class="notifications-box"
                    :class="[props.item.type]"
                >
                    <h3
                        v-if="props.item.title"
                        class="notifications-box-title"
                    >
                        {{ props.item.title }}
                    </h3>
                    <p
                        v-if="props.item.text"
                        v-html="props.item.text"
                        class="notifications-box-text"
                    ></p>
                    <button
                        @click="props.close"
                        class="notifications-box-close"
                        aria-label="Close"
                    >
                        &times;
                    </button>
                </div>
            </template>
        </notifications>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex';

    export default {
        name: 'App',
        data() {
            return {
                isLoading: true,
            };
        },
        methods: {
            ...mapMutations('auth', [
                'setAccessToken',
                'setUser',
            ]),
        },
        async created() {
            const response = await fetch(process.env.VUE_APP_API_REFRESH_TOKEN_URL, {
                method: 'POST',
                credentials: 'include',
            });

            const { accessToken, user } = await response.json();

            if (accessToken && user) {
                this.setAccessToken(accessToken);
                this.setUser(user);
            } else {
                this.setAccessToken(null);
                this.setUser(null);
            }

            this.isLoading = false;
            this.$router.start();
        },
    };
</script>
