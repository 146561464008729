<template>
    <div>
        <div class="row">
            <div class="col-lg-8 mb-3 mb-lg-0">
                <div class="card shadow-lg">
                    <div
                        class="card-header d-flex align-items-center sticky-top"
                    >
                        <h3>Producten</h3>

                        <div class="search-holder ml-auto">
                            <p-form-input
                                v-model="searchQuery"
                                label="Zoeken"
                                class="search mb-0"
                            ></p-form-input>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive-lg">
                            <table class="table mb-0">
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Varianten</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="item in sortedProducts"
                                        :key="item._id"
                                    >
                                        <td>{{ item.title }}</td>
                                        <td>
                                            <span
                                                v-for="variant in item.variants"
                                                :key="`variant-${variant._id}`"
                                            >
                                                <small>
                                                    {{ variant.description }}
                                                    {{
                                                        variant.price | currency
                                                    }}
                                                    {{
                                                        variant.quantityType
                                                            | perQuantityType
                                                    }} </small
                                                ><br />
                                            </span>
                                        </td>
                                        <td class="narrow">
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <router-link
                                                    :to="{
                                                        name: 'products.edit',
                                                        params: {
                                                            id: item._id,
                                                        },
                                                    }"
                                                    class="btn btn-symbol btn-primary mr-2"
                                                    aria-label="Wijzig"
                                                >
                                                    <p-icon name="edit" />
                                                </router-link>
                                                <button
                                                    @click="
                                                        handleDelete(item._id)
                                                    "
                                                    class="btn btn-symbol btn-secondary"
                                                    aria-label="Verwijder"
                                                >
                                                    <p-icon name="delete" />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="card shadow-lg sticky-lg-top">
                    <div class="card-header">
                        <h3>Toevoegen</h3>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="create" action="/">
                            <p-form-input
                                v-model="formData.title"
                                label="Titel"
                                required
                            />

                            <p-form-input-number
                                v-model="formData.vat"
                                label="BTW"
                                required
                            />

                            <button class="btn btn-primary">Toevoegen</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <router-view />
    </div>
</template>

<script>
import {
    QUERY_PRODUCTS,
    MUTATION_DELETE_PRODUCT,
    MUTATION_CREATE_PRODUCT,
} from '@/graphql/product';

export default {
    name: 'ProductsIndex',
    apollo: {
        products: QUERY_PRODUCTS,
    },
    data() {
        return {
            searchQuery: '',
            products: [],
            formData: {
                title: '',
                vat: 9,
            },
        };
    },
    computed: {
        sortedProducts() {
            return Array.prototype.slice
                .call(this.filteredProducts)
                .sort((a, b) => {
                    if (a.title < b.title) return -1;
                    if (a.title > b.title) return 1;
                    return 0;
                });
        },

        filteredProducts() {
            return Array.prototype.slice
                .call(this.products)
                .filter((product) =>
                    product.title
                        .toLowerCase()
                        .includes(this.searchQuery.toLowerCase())
                );
        },
    },
    methods: {
        async create() {
            const cachedFormData = JSON.parse(JSON.stringify(this.formData));

            this.formData = {
                title: '',
                vat: 9,
            };

            try {
                await this.$apollo.mutate({
                    mutation: MUTATION_CREATE_PRODUCT,
                    variables: {
                        ...cachedFormData,
                    },
                    update: (store, { data: { createProduct } }) => {
                        this.$router.push({
                            name: 'products.edit',
                            params: {
                                id: createProduct._id,
                            },
                        });

                        const queryData = store.readQuery({
                            query: QUERY_PRODUCTS,
                        });

                        queryData.products.push(createProduct);

                        store.writeQuery({
                            query: QUERY_PRODUCTS,
                            data: queryData,
                        });
                    },
                });

                this.$notify({
                    title: 'Product is succesvol toegevoegd!',
                    type: 'success',
                    duration: 1000,
                });
            } catch (err) {
                this.formData = cachedFormData;
            }
        },
        async handleDelete(id) {
            await this.confirmModal();

            await this.$apollo.mutate({
                mutation: MUTATION_DELETE_PRODUCT,
                variables: { id },
                update: (store) => {
                    const queryData = store.readQuery({
                        query: QUERY_PRODUCTS,
                    });

                    const index = queryData.products.findIndex(
                        (product) => id === product._id
                    );

                    queryData.products.splice(index, 1);

                    store.writeQuery({
                        query: QUERY_PRODUCTS,
                        data: queryData,
                    });
                },
            });

            this.$notify({
                title: 'Product is succesvol verwijderd!',
                type: 'success',
                duration: 1000,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.search {
    width: 200px;
}
</style>
