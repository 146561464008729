<template>
    <form @submit.prevent="handleUpdate">
        <div class="row gutter-sm">
            <div class="col-lg-auto mb-3 mb-lg-0">
                <button
                    @click="handleDelete"
                    type="button"
                    class="btn btn-symbol btn-secondary btn-lg"
                    aria-label="Verwijder"
                >
                    <p-icon name="delete" />
                </button>
            </div>
            <div class="col-lg mb-3 mb-lg-0">
                <p-form-input
                    v-model="inputVariant.description"
                    label="Beschrijving"
                    parent-class="mb-0"
                    required
                />
            </div>
            <div class="col-lg mb-3 mb-lg-0">
                <p-form-select
                    v-model="inputVariant.quantityType"
                    label="Eenheid"
                    :options="[
                        {
                            label: 'Colli',
                            value: 'PACKAGE',
                        },
                        {
                            label: 'Kilogram',
                            value: 'KILOGRAM',
                        },
                        {
                            label: 'Stuks',
                            value: 'PIECE',
                        },
                    ]"
                    parent-class="mb-0"
                    required
                />
            </div>
            <div class="col-lg mb-3 mb-lg-0">
                <p-form-input-money
                    v-model="inputVariant.price"
                    label="Prijs"
                    parent-class="mb-0"
                    required
                />
            </div>
            <div class="col-lg-auto">
                <button
                    class="btn btn-primary btn-symbol btn-lg"
                    :disabled="!canUpdate"
                    aria-label="Opslaan"
                >
                    <p-icon name="save" />
                </button>
            </div>
        </div>
    </form>
</template>

<script>
    import diff from 'deep-diff';

    import {
        MUTATION_DELETE_PRODUCTVARIANT,
        MUTATION_UPDATE_PRODUCTVARIANT,
        QUERY_PRODUCTS,
    } from '@/graphql/product';

    export default {
        name: 'ProductVariant',
        props: {
            value: {
                required: true,
                type: Object,
            },
        },
        data() {
            return {
                inputVariant: null,
            };
        },
        computed: {
            canUpdate() {
                return !!diff(this.inputVariant, this.value);
            },
        },
        methods: {
            async handleDelete() {
                await this.confirmModal();

                const id = this.value._id;

                await this.$apollo.mutate({
                    mutation: MUTATION_DELETE_PRODUCTVARIANT,
                    variables: { id },
                    refetchQueries: [{ query: QUERY_PRODUCTS }],
                });

                this.$emit('remove', id);

                this.$notify({
                    title: 'Product variant is succesvol verwijderd!',
                    type: 'success',
                    duration: 1000,
                });
            },

            async handleUpdate() {
                await this.$apollo.mutate({
                    mutation: MUTATION_UPDATE_PRODUCTVARIANT,
                    variables: {
                        ...this.inputVariant,
                        id: this.value._id,
                        price: +this.inputVariant.price,
                    },
                    refetchQueries: [{ query: QUERY_PRODUCTS }],
                });

                this.$notify({
                    title: 'Product variant is succesvol aangepast!',
                    type: 'success',
                    duration: 1000,
                });
            },
        },
        watch: {
            value: {
                immediate: true,
                handler(value) {
                    this.inputVariant = JSON.parse(JSON.stringify(value));
                },
            },
        },
    };
</script>
