var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[(!_vm.editMode)?[_c('td',[_vm._v(" "+_vm._s(_vm.value.title)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.value.description)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("quantityType")(_vm.value.quantityType))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("number")(_vm.value.vat))+"% ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("number")(_vm.value.quantity))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.value.price))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.value.price * _vm.value.quantity))+" ")]),_c('td',{staticClass:"narrow"},[_c('button',{staticClass:"btn btn-link btn-symbol btn-lg",on:{"click":function($event){_vm.editMode = true}}},[_c('p-icon',{attrs:{"name":"edit"}})],1)])]:_c('td',{staticClass:"edit-row p-3",attrs:{"colspan":"1000"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleUpdate.apply(null, arguments)}}},[_c('div',{staticClass:"row gutter-sm"},[_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-symbol btn-danger btn-lg",attrs:{"type":"button","aria-label":"Verwijder"},on:{"click":_vm.handleDelete}},[_c('p-icon',{attrs:{"name":"delete"}})],1)]),_c('div',{staticClass:"col"},[_c('p-form-input',{attrs:{"label":"Product","parent-class":"mb-0","required":""},model:{value:(_vm.inputValue.title),callback:function ($$v) {_vm.$set(_vm.inputValue, "title", $$v)},expression:"inputValue.title"}})],1),_c('div',{staticClass:"col"},[_c('p-form-input',{attrs:{"label":"Beschrijving","parent-class":"mb-0"},model:{value:(_vm.inputValue.description),callback:function ($$v) {_vm.$set(_vm.inputValue, "description", $$v)},expression:"inputValue.description"}})],1),_c('div',{staticClass:"col"},[_c('p-form-select',{attrs:{"label":"Eenheid","options":[
                            {
                                label: 'Colli',
                                value: 'PACKAGE',
                            },
                            {
                                label: 'Kilogram',
                                value: 'KILOGRAM',
                            },
                            {
                                label: 'Stuks',
                                value: 'PIECE',
                            } ],"parent-class":"mb-0","required":""},model:{value:(_vm.inputValue.quantityType),callback:function ($$v) {_vm.$set(_vm.inputValue, "quantityType", $$v)},expression:"inputValue.quantityType"}})],1),_c('div',{staticClass:"col"},[_c('p-form-input-number',{attrs:{"label":"Aantal","parent-class":"mb-0","required":""},model:{value:(_vm.inputValue.quantity),callback:function ($$v) {_vm.$set(_vm.inputValue, "quantity", $$v)},expression:"inputValue.quantity"}})],1),_c('div',{staticClass:"col"},[_c('p-form-input-money',{attrs:{"label":"Prijs","parent-class":"mb-0","required":""},model:{value:(_vm.inputValue.price),callback:function ($$v) {_vm.$set(_vm.inputValue, "price", $$v)},expression:"inputValue.price"}})],1),_c('div',{staticClass:"col-auto d-flex align-items-center"},[_c('strong',{staticClass:"text-right total-price"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalPrice))+" ")])]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-primary btn-symbol btn-lg",attrs:{"aria-label":"Opslaan"}},[_c('p-icon',{attrs:{"name":"save"}})],1)]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-link px-0",attrs:{"type":"button"},on:{"click":_vm.cancel}},[_vm._v(" Annuleren ")])])])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }