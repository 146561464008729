var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{attrs:{"to":"modal"}},[(_vm.product)?_c('modal',{attrs:{"size":"large","title":("Product '" + (_vm.product.title) + "'")},on:{"close":function($event){return _vm.$router.push({ name: 'products' })}}},[_c('div',{staticClass:"card shadow-lg mb-5"},[_c('div',{staticClass:"card-header"},[_c('form',{staticClass:"row gutter-sm",attrs:{"action":"/"},on:{"submit":function($event){$event.preventDefault();return _vm.createProductVariant.apply(null, arguments)}}},[_c('div',{staticClass:"col-lg mb-3 mb-lg-0"},[_c('p-form-input',{attrs:{"label":"Beschrijving","parent-class":"mb-0","required":""},model:{value:(_vm.variant.description),callback:function ($$v) {_vm.$set(_vm.variant, "description", $$v)},expression:"variant.description"}})],1),_c('div',{staticClass:"col-lg mb-3 mb-lg-0"},[_c('p-form-select',{attrs:{"label":"Eenheid","options":[
                                {
                                    label: 'Selecteer',
                                    value: '',
                                },
                                {
                                    label: 'Colli',
                                    value: 'PACKAGE',
                                },
                                {
                                    label: 'Kilogram',
                                    value: 'KILOGRAM',
                                },
                                {
                                    label: 'Stuks',
                                    value: 'PIECE',
                                } ],"parent-class":"mb-0","required":""},model:{value:(_vm.variant.quantityType),callback:function ($$v) {_vm.$set(_vm.variant, "quantityType", $$v)},expression:"variant.quantityType"}})],1),_c('div',{staticClass:"col-lg mb-3 mb-lg-0"},[_c('p-form-input-money',{attrs:{"label":"Prijs","parent-class":"mb-0","required":""},model:{value:(_vm.variant.price),callback:function ($$v) {_vm.$set(_vm.variant, "price", $$v)},expression:"variant.price"}})],1),_c('div',{staticClass:"col-lg-auto d-none d-lg-block"},[_c('button',{staticClass:"btn btn-primary btn-symbol btn-lg",attrs:{"aria-label":"Toevoegen"}},[_c('p-icon',{attrs:{"name":"add"}})],1)]),_c('div',{staticClass:"col-12 d-lg-none"},[_c('button',{staticClass:"btn btn-primary w-100"},[_vm._v(" Toevoegen ")])])])]),(_vm.productVariants.length)?_vm._l((_vm.productVariants),function(variant,index){return _c('div',{key:("product-variant-" + index + "-" + (variant._id)),class:[
                        'card-body',
                        { 'border-bottom': index !== _vm.productVariants.length - 1 } ]},[_c('product-variant',{on:{"remove":_vm.handleRemove},model:{value:(_vm.productVariants[index]),callback:function ($$v) {_vm.$set(_vm.productVariants, index, $$v)},expression:"productVariants[index]"}})],1)}):_c('div',{staticClass:"card-body"},[_c('small',[_vm._v("Nog geen product varianten")])])],2),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h3',[_vm._v("Product informatie")])]),_c('div',{staticClass:"card-body"},[_c('form',{attrs:{"action":"/"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('p-form-input',{attrs:{"label":"Titel","required":""},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('p-form-input-number',{attrs:{"label":"BTW","required":""},model:{value:(_vm.formData.vat),callback:function ($$v) {_vm.$set(_vm.formData, "vat", $$v)},expression:"formData.vat"}}),_c('button',{staticClass:"btn btn-primary"},[_vm._v(" Opslaan ")])],1)])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }