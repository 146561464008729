<template>
    <tr>
        <template v-if="!editMode">
            <td>
                {{ value.title }}
            </td>
            <td>
                {{ value.description }}
            </td>
            <td>
                {{ value.quantityType | quantityType }}
            </td>
            <td class="text-right">{{ value.vat | number }}%</td>
            <td class="text-right">
                {{ value.quantity | number }}
            </td>
            <td class="text-right">
                {{ value.price | currency }}
            </td>
            <td class="text-right">
                {{ (value.price * value.quantity) | currency }}
            </td>
            <td class="narrow">
                <button
                    @click="editMode = true"
                    class="btn btn-link btn-symbol btn-lg"
                    :class="{ disabled: isLocked }"
                >
                    <p-icon name="edit" />
                </button>
            </td>
        </template>
        <td v-else colspan="1000" class="edit-row p-3">
            <form @submit.prevent="handleUpdate">
                <div class="row gutter-sm">
                    <div class="col-auto">
                        <button
                            @click="handleDelete"
                            type="button"
                            class="btn btn-symbol btn-danger btn-lg"
                            aria-label="Verwijder"
                        >
                            <p-icon name="delete" />
                        </button>
                    </div>
                    <div class="col">
                        <p-form-input
                            v-model="inputValue.title"
                            label="Product"
                            parent-class="mb-0"
                            required
                        />
                    </div>
                    <div class="col">
                        <p-form-input
                            v-model="inputValue.description"
                            label="Beschrijving"
                            parent-class="mb-0"
                        />
                    </div>
                    <div class="col">
                        <p-form-select
                            v-model="inputValue.quantityType"
                            label="Eenheid"
                            :options="[
                                {
                                    label: 'Colli',
                                    value: 'PACKAGE',
                                },
                                {
                                    label: 'Kilogram',
                                    value: 'KILOGRAM',
                                },
                                {
                                    label: 'Stuks',
                                    value: 'PIECE',
                                },
                            ]"
                            parent-class="mb-0"
                            required
                        />
                    </div>
                    <div class="col">
                        <p-form-input-number
                            v-model="inputValue.quantity"
                            label="Aantal"
                            parent-class="mb-0"
                            required
                        />
                    </div>
                    <div class="col">
                        <p-form-input-money
                            v-model="inputValue.price"
                            label="Prijs"
                            parent-class="mb-0"
                            required
                        />
                    </div>
                    <div class="col-auto d-flex align-items-center">
                        <strong class="text-right total-price">
                            {{ totalPrice | currency }}
                        </strong>
                    </div>
                    <div class="col-auto">
                        <button
                            class="btn btn-primary btn-symbol btn-lg"
                            aria-label="Opslaan"
                        >
                            <p-icon name="save" />
                        </button>
                    </div>
                    <div class="col-auto">
                        <button
                            @click="cancel"
                            type="button"
                            class="btn btn-link px-0"
                        >
                            Annuleren
                        </button>
                    </div>
                </div>
            </form>
        </td>
    </tr>
</template>

<script>
import diff from 'deep-diff';

import {
    MUTATION_DELETE_ORDERLINE,
    MUTATION_UPDATE_ORDERLINE,
} from '@/graphql/order-line';

import { QUERY_ORDER } from '@/graphql/order';

export default {
    name: 'OrderLine',
    props: {
        value: {
            required: true,
            type: Object,
        },
        order: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            editMode: false,
            inputValue: null,
        };
    },
    computed: {
        isLocked() {
            return ['PAID', 'BILLED'].includes(this.order.status);
        },

        canUpdate() {
            return !!diff(this.inputValue, this.value);
        },

        totalPrice() {
            return this.inputValue.price * this.inputValue.quantity;
        },
    },
    methods: {
        async handleDelete() {
            await this.confirmModal();

            const id = this.value._id;

            await this.$apollo.mutate({
                mutation: MUTATION_DELETE_ORDERLINE,
                variables: {
                    id,
                },
            });

            this.$emit('remove', id);

            this.$notify({
                title: 'Order regel is succesvol verwijderd!',
                type: 'success',
                duration: 1000,
            });
        },

        async handleUpdate() {
            await this.$apollo.mutate({
                mutation: MUTATION_UPDATE_ORDERLINE,
                variables: {
                    ...this.inputValue,
                    id: this.value._id,
                    price: +this.inputValue.price,
                },
                refetchQueries: [
                    { query: QUERY_ORDER, variables: { id: this.order._id } },
                ],
            });

            this.editMode = false;

            this.$notify({
                title: 'Order regel is succesvol aangepast!',
                type: 'success',
                duration: 1000,
            });
        },

        cancel() {
            this.setInputValue();
            this.editMode = false;
        },

        setInputValue() {
            this.inputValue = JSON.parse(JSON.stringify(this.value));
        },
    },
    watch: {
        value: {
            immediate: true,
            handler() {
                this.setInputValue();
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.order-line {
    border-bottom: 1px solid $gray;
}

.total-price {
    display: block;
    width: 100px;
}

td {
    border-bottom: 1px solid #dee2e6;
}

.edit-row {
    max-width: none;
    background: rgba($primary, 0.1);

    form {
        min-width: 1200px;
    }
}
</style>
