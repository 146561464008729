<template>
    <div v-if="client">
        <div class="d-flex mb-3">
            <back :to="{ name: 'clients' }" />
        </div>

        <div class="card shadow-lg">
            <div class="card-header">
                <h3>Wijzig "{{ client.name }}"</h3>
            </div>
            <div class="card-body">
                <client-fields
                    v-model="formData"
                    @submit="update"
                    submit-text="Opslaan"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import {
        MUTATION_UPDATE_CLIENT,
        QUERY_CLIENT,
    } from '@/graphql/client';

    import ClientFields from '@/pages/clients/fields';

    export default {
        name: 'ClientEdit',
        components: {
            ClientFields,
        },
        data() {
            return {
                formData: null,
            };
        },
        apollo: {
            client: {
                query: QUERY_CLIENT,
                variables() {
                    return {
                        id: this.$route.params.id,
                    };
                },
                result({ data: { client } }) {
                    this.formData = JSON.parse(JSON.stringify(client));
                },
            },
        },
        methods: {
            async update() {
                await this.$apollo.mutate({
                    mutation: MUTATION_UPDATE_CLIENT,
                    variables: {
                        id: this.formData._id,
                        ...this.formData,
                    },
                });

                this.$notify({
                    title: 'Klant is succesvol aangepast!',
                    type: 'success',
                    duration: -1,
                });
            },
        },
    };
</script>
