<template>
    <div
        class="d-flex flex-column justify-content-center align-items-center auth-page h-100 content px-3"
    >
        <div class="login-content">
            <div class="card">
                <div class="card-header d-flex justify-content-center">
                    <div class="logo">
                        <img
                            src="/logo.svg"
                            alt="Satisfaction logo"
                            class="logo-image"
                        />
                        <span class="logo-text">Schutter Fruit</span>
                    </div>
                </div>
                <div class="card-body">
                    <form @submit.prevent="handleSubmit" action="/">
                        <p-form-input
                            v-model="formData.email"
                            label="E-mailadres"
                            type="email"
                            class="login-form-control"
                            required
                        />

                        <p-form-input
                            v-model="formData.password"
                            label="Wachtwoord"
                            type="password"
                            class="login-form-control"
                            required
                        />

                        <button class="btn btn-secondary w-100">Login</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapMutations } from 'vuex';

export default {
    name: 'Login',
    layout: 'default',
    data() {
        return {
            formData: {
                email: '',
                password: '',
            },
            errors: [],
        };
    },
    methods: {
        ...mapMutations('auth', ['setAccessToken', 'setUser']),

        async handleSubmit() {
            this.errors = [];

            const { data } = await this.$apollo.query({
                query: gql`
                    query ($email: String!, $password: String!) {
                        login(email: $email, password: $password) {
                            accessToken
                            user {
                                email
                                username
                            }
                        }
                    }
                `,
                variables: { ...this.formData },
            });

            if (!data.login) return;

            Object.values(this.$apollo.provider.clients).forEach((client) => {
                client.cache.reset();
            });

            this.setAccessToken(data.login.accessToken);
            this.setUser(data.login.user);

            this.$router.push({ name: 'home' });
        },
    },
};
</script>

<style lang="scss" scoped>
.card {
    background: $dark;
    border: none;

    &-header {
        border: none;
    }
}

.logo {
    display: flex;
    align-items: center;

    &-image {
        width: auto;
        height: 72px;
    }

    &-text {
        display: block;
        margin-left: 12px;
        font-family: 'Patua One', cursive;
        font-size: 40px;
        font-weight: normal;
        color: #fff;
        text-align: center;
    }
}

.login-form-control {
    border: none;
}

.login-content {
    width: 100%;
    max-width: 400px;
}

.auth-page {
    background: $dark;
}
</style>
