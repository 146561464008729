import gql from 'graphql-tag';

export const MUTATION_CREATE_ORDERLINE = gql`
    mutation (
        $order: ID!,
        $product: ID,
        $provider: ID!,
        $quantity: Float!,
        $type: OrderLineType!,
    ) {
        createOrderLine(
            order: $order,
            product: $product,
            provider: $provider,
            quantity: $quantity,
            type: $type,
        ){
            _id
            description
            price
            quantity
            quantityType
            title
            type
            vat
        }
    }
`;

export const MUTATION_UPDATE_ORDERLINE = gql`
    mutation (
        $id: ID!,
        $description: String,
        $price: Float!,
        $quantity: Float!,
        $quantityType: QuantityType!,
        $title: String!,
        $vat: Float!,
    ) {
        updateOrderLine(
            id: $id,
            description: $description,
            price: $price,
            quantity: $quantity,
            quantityType: $quantityType,
            title: $title,
            vat: $vat,
        ){
            _id
            description
            price
            quantity
            quantityType
            title
            type
            vat
        }
    }
`;

export const MUTATION_DELETE_ORDERLINE = gql`
    mutation ($id: ID!) {
        deleteOrderLine(id: $id)
    }
`;
