import 'dotenv/config';
import Vue from 'vue';

import '@/assets/scss/app.scss';
import {
    apolloProvider,
    i18n,
    router,
} from '@/config';
import app from '@/App';
import store from '@/store';
import './registerServiceWorker'

if (
    process.env.NODE_ENV !== 'development'
    && window.location.protocol !== 'https:'
) {
    window.location.href = window.location.href.replace('http://', 'https://');
}

new Vue({
    apolloProvider,
    i18n,
    router,
    store,
    render: h => h(app),
}).$mount('#app')
