<template>
    <div
        class="datepicker form-group has-inline-label"
        :class="[
            {
                'has-placeholder': !!placeholder,
            },
            parentClass,
        ]"
    >
        <label
            :class="[
                'form-label',
                {
                    'move-label-to-top': moveLabelToTop,
                },
            ]"
            :for="uniqueId"
        >
            {{ label }}
        </label>
        <div class="input-group">
            <v-date-picker
                v-model="inputValue"
                v-bind="$attrs"
                :formats="{
                    input: 'DD-MM-YYYY',
                }"
                :class="[
                    {
                        readonly,
                        disabled,
                        'move-label-to-top': moveLabelToTop,
                    },
                    'form-control',
                ]"
                :attributes="[
                    {
                        key: 'today',
                        dates: new Date(),
                        highlight: {
                            backgroundColor: '#e5e5e5',
                        },
                        contentStyle: {
                            color: '#1a2a38',
                        },
                        popover: {
                            label: 'Today',
                        },
                    },
                ]"
                mode="single"
            />
        </div>
        <input
            type="text"
            :id="uniqueId"
            :name="name"
            :required="required"
            :value="ISOString"
            class="datepicker-input"
        >
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'DatePicker',
        props: {
            value: {
                type: String,
                default: moment().toISOString(),
            },
            label: {
                type: String,
                default: 'Label',
            },
            name: {
                type: String,
                default: 'date',
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            parentClass: {
                type: String,
                default: '',
            },
            placeholder: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                focus: false,
                inputValue: moment(this.value).toDate(),
                uniqueId: this.$id('datepicker'),
            };
        },
        computed: {
            ISOString() {
                return moment(this.inputValue).toISOString();
            },

            moveLabelToTop() {
                return this.placeholder || this.focus || this.inputValue !== '';
            },

            inputPlaceholder() {
                return this.placeholder || this.label;
            },
        },

        watch: {
            value() {
                this.inputValue = moment(this.value).toDate();
            },

            inputValue() {
                this.$emit('input', this.ISOString);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .datepicker {
        position: relative;
        z-index: 100;

        &.focus {
            z-index: 101;
        }

        &-input {
            position: absolute;
            bottom: 0;
            left: 0;
            pointer-events: none;
            opacity: 0;
        }
    }
</style>

<style lang="scss">
    .datepicker {
        .form-control {
            padding-right: 40px;

            input {
                font-size: 14px;
                line-height: 18px;
                color: $body;

                @include placeholder {
                    color: $dark;
                    opacity: 0.7;
                }
            }
        }

        .input-group {
            > span {
                display: block;
                width: 100%;
            }
        }
    }
</style>
