import gql from 'graphql-tag';

export const QUERY_INVOICES = gql`
    query ($clientId: ID) {
        invoices(clientId: $clientId) {
            _id
            clientCity
            clientContactName
            clientContactPhone
            clientName
            clientNotes
            clientPostalCode
            clientStreet
            clientStreetNumber
            createdAt
            date
            orders {
                _id
            }
            notes
            number
            status
            updatedAt
        }
    }
`;

export const QUERY_INVOICE = gql`
    query ($id: ID!) {
        invoice(id: $id) {
            _id
            clientCity
            clientContactName
            clientContactPhone
            clientName
            clientNotes
            clientPostalCode
            clientStreet
            clientStreetNumber
            createdAt
            date
            orders {
                _id
                createdAt
                date
                number
                status
                type
                clientNotes
                lines {
                    _id
                    description
                    price
                    quantity
                    quantityType
                    title
                    type
                    vat
                }
            }
            notes
            number
            status
            updatedAt
        }
    }
`;

export const MUTATION_CREATE_INVOICE = gql`
    mutation (
        $client: ID!
        $date: String!
        $notes: String
        $orders: [ID!]!
        $status: InvoiceStatus!
    ) {
        createInvoice(
            client: $client
            date: $date
            notes: $notes
            orders: $orders
            status: $status
        ) {
            _id
            clientCity
            clientContactName
            clientContactPhone
            clientName
            clientNotes
            clientPostalCode
            clientStreet
            clientStreetNumber
            createdAt
            date
            orders {
                _id
            }
            notes
            number
            status
            updatedAt
        }
    }
`;

export const MUTATION_UPDATE_INVOICE = gql`
    mutation (
        $id: ID!
        $date: String!
        $notes: String
        $status: InvoiceStatus!
    ) {
        updateInvoice(id: $id, date: $date, notes: $notes, status: $status) {
            _id
            clientCity
            clientContactName
            clientContactPhone
            clientName
            clientNotes
            clientPostalCode
            clientStreet
            clientStreetNumber
            createdAt
            date
            orders {
                _id
            }
            notes
            number
            status
            updatedAt
        }
    }
`;

export const MUTATION_DELETE_INVOICE = gql`
    mutation ($id: ID!) {
        deleteInvoice(id: $id)
    }
`;
