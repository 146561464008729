import Vue from 'vue';
import VueRouter from 'vue-router';

import clients from '@/pages/clients/index';
import clientsEdit from '@/pages/clients/edit';
import creditOrders from '@/pages/credit-orders/index';
import creditOrdersEdit from '@/pages/credit-orders/edit';
import defaultTemplate from '@/layouts/default';
import emballage from '@/pages/emballage/index';
import emballageEdit from '@/pages/emballage/edit';
import invoices from '@/pages/invoices/index';
import invoicesEdit from '@/pages/invoices/edit';
import login from '@/pages/auth/login';
import orders from '@/pages/orders/index';
import ordersEdit from '@/pages/orders/edit';
import products from '@/pages/products/index';
import productsEdit from '@/pages/products/edit';
import store from '@/store';

Vue.use(VueRouter);

const site = 'Schutter Fruit';
const routes = [
    {
        name: 'home',
        path: '/',
        redirect: { name: 'orders' },
    },
    {
        component: login,
        name: 'login',
        path: '/login',
        meta: {
            middleware: ['noAuth'],
            title: `Login - ${site}`,
        },
    },
    {
        component: defaultTemplate,
        name: 'orders',
        path: '/orders',
        redirect: { name: 'orders.index' },
        children: [
            {
                component: orders,
                name: 'orders.index',
                path: '',
                meta: {
                    middleware: ['auth'],
                    title: `Orders - ${site}`,
                },
            },
            {
                component: ordersEdit,
                name: 'orders.edit',
                path: ':id/edit',
                meta: {
                    middleware: ['auth'],
                    title: `Order wijzigen - ${site}`,
                },
            },
        ],
    },
    {
        component: defaultTemplate,
        name: 'credit-orders',
        path: '/credit-orders',
        redirect: { name: 'credit-orders.index' },
        children: [
            {
                component: creditOrders,
                name: 'credit-orders.index',
                path: '',
                meta: {
                    middleware: ['auth'],
                    title: `Creditfacturen - ${site}`,
                },
            },
            {
                component: creditOrdersEdit,
                name: 'credit-orders.edit',
                path: ':id/edit',
                meta: {
                    middleware: ['auth'],
                    title: `Creditfacturen - ${site}`,
                },
            },
        ],
    },
    {
        component: defaultTemplate,
        name: 'invoices',
        path: '/invoices',
        redirect: { name: 'invoices.index' },
        children: [
            {
                component: invoices,
                name: 'invoices.index',
                path: '',
                meta: {
                    middleware: ['auth'],
                    title: `Facturen - ${site}`,
                },
            },
            {
                component: invoicesEdit,
                name: 'invoices.edit',
                path: ':id/edit',
                meta: {
                    middleware: ['auth'],
                    title: `Factuur wijzigen - ${site}`,
                },
            },
        ],
    },
    {
        component: defaultTemplate,
        name: 'clients',
        path: '/clients',
        redirect: { name: 'clients.index' },
        children: [
            {
                component: clients,
                name: 'clients.index',
                path: '',
                meta: {
                    middleware: ['auth'],
                    title: `Klanten - ${site}`,
                },
            },
            {
                component: clientsEdit,
                name: 'clients.edit',
                path: ':id/edit',
                meta: {
                    middleware: ['auth'],
                    title: `Klant wijzigen - ${site}`,
                },
            },
        ],
    },
    {
        component: defaultTemplate,
        name: 'products',
        path: '/products',
        redirect: { name: 'products.index' },
        children: [
            {
                component: products,
                name: 'products.index',
                path: '',
                meta: {
                    middleware: ['auth'],
                    title: `Producten - ${site}`,
                },
                children: [
                    {
                        component: productsEdit,
                        name: 'products.edit',
                        path: ':id/edit',
                        meta: {
                            middleware: ['auth'],
                            title: `Product wijzigen - ${site}`,
                        },
                    },
                ],
            },
        ],
    },
    {
        component: defaultTemplate,
        name: 'emballage',
        path: '/emballage',
        redirect: { name: 'emballage.index' },
        children: [
            {
                component: emballage,
                name: 'emballage.index',
                path: '',
                meta: {
                    middleware: ['auth'],
                    title: `Emballages - ${site}`,
                },
            },
            {
                component: emballageEdit,
                name: 'emballage.edit',
                path: ':id/edit',
                meta: {
                    middleware: ['auth'],
                    title: `Emballage wijzigen - ${site}`,
                },
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (
            (from.name === 'products.index' && to.name === 'products.edit') ||
            (from.name === 'products.edit' && to.name === 'products.index')
        ) {
            return null;
        }

        return savedPosition || { x: 0, y: 0 };
    },
});

const waitForManualStart = new Promise((resolve) => {
    router.start = resolve;
});

export default (() => {
    router.beforeEach(async (to, from, next) => {
        await waitForManualStart;

        document.title = to.meta.title || site;

        if (
            to?.meta?.middleware.includes('auth') &&
            !store.state.auth.accessToken
        ) {
            next({ name: 'login' });
            return;
        }

        if (
            to?.meta?.middleware.includes('noAuth') &&
            store.state.auth.accessToken
        ) {
            next({ name: 'home' });
            return;
        }

        next();
    });

    return router;
})();
